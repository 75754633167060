import { useEffect, useState } from "react";
import TabButtons from "../../components/TabButtons/TabButtons";
import Search from "../../components/Search/Search";
import axios from "axios";
import { toast } from "react-toastify";
import AllEvents from "./AllEvents";

const MainEventsStudent = ({ user }) => {
  const [selected, setSelected] = useState("Explore Events");
  const buttons = [{ name: "Explore Events" }, { name: "Registered Events" }];
  const [search, setSearch] = useState("");
  const [events, setEvents] = useState([]);
  const [registeredEvents, setRegisteredEvents] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const fetchEvents = async () => {
    if (!loading) {
      setLoading(true);
      await axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/api/getEvents`,
          { page, limit: 15 },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((res) => {
          if (res.data.msg === "success") {
            setEvents(res.data.events);
            if (res.data.events.length < 15) {
              setHasMore(false);
            }
          } else {
            setHasMore(false);
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error("Failed to fetch events");
          setLoading(false);
        })
        .finally(() => {
          setLoading(false);
        });
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEvents();
  }, [page]);

  useEffect(() => {
    setRegisteredEvents(() => {
      const registered = events.map((eve) => {
        if (eve.participants.includes(user._id)) {
          return eve;
        }
      });
      return registered;
    });
  }, [events]);

  const fetchMoreEvents = () => {
    if (!loading && hasMore) {
      setPage((prevPage) => prevPage + 1); // Increment page number to fetch more posts
    }
  };

  const [eventstoshow, setEventsToShow] = useState([]);
  const [registeredToShow, setRegisteredToShow] = useState([]);

  useEffect(() => {
    if (search) {
      setEventsToShow(
        events.filter((eve) =>
          eve?.title.toLowerCase().includes(search?.toLowerCase())
        )
      );
      setRegisteredToShow(
        registeredEvents.filter((eve) =>
          eve?.title.toLowerCase().includes(search?.toLowerCase())
        )
      );
    } else {
      setEventsToShow(events);
      setRegisteredToShow(registeredEvents);
    }
  }, [search, events, registeredEvents]);

  return (
    <div
      className="marginsleftrightCompany"
      style={{ padding: "1.875rem 0rem" }}
    >
      <div className="center">
        <div className="headerCompany">
          <p className="studentHeading">Events Search</p>
          <p
            className="gray60011400"
            style={{ fontSize: "14px", marginTop: "0.25rem" }}
          >
            Create events for students around the globe and attract talent.
          </p>
          <div
            className="space flex-wrap align-items-center"
            style={{ marginTop: "1.25rem", gap: "1rem" }}
          >
            <TabButtons
              buttons={buttons}
              selected={selected}
              setSelected={setSelected}
            />
            <div
              className="d-flex flex-wrap align-items-center"
              style={{ gap: "1rem" }}
            >
              <Search search={search} setSearch={setSearch} />
              {/* <button
                    className="Student-dashboard-filter-button"
                    onClick={() => {
                      setFilters(true);
                    }}
                  >
                    Filters <TuneIcon />
                  </button> */}
            </div>
          </div>
          <div style={{ marginTop: "1.875rem" }}>
            {selected === "Explore Events" && (
              <AllEvents
                events={eventstoshow}
                fetchMoreEvents={fetchMoreEvents}
                hasMore={hasMore}
                loading={loading}
                user={user}
                setEvents={setEvents}
              />
            )}
            {selected === "Registered Events" && (
              <AllEvents
                events={registeredToShow}
                fetchMoreEvents={fetchMoreEvents}
                hasMore={hasMore}
                loading={loading}
                user={user}
                setEvents={setEvents}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainEventsStudent;
