const handleContextMenu = (e) => {
  e.preventDefault(); // Prevent right-click context menu
};
const SecondSec = ({ classes, vid }) => {
  return (
    <div className={`${classes}`}>
      <div className="marginsleftrightCompany">
        <div className="center">
          <div className="headerCompany center align-items-center">
            <video
              src={vid}
              autoPlay
              loop
              muted
              playsInline
              controls={false}
              className="img-fluid"
              style={{
                aspectRatio: "1.78666666667",
                // width: "100%",
                maxWidth: "50vw",
                objectFit: "cover", // Ensure video covers the entire area
                display: "block", // Remove any default inline space
              }}
              onContextMenu={handleContextMenu}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default SecondSec;
