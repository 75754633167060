import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Loader from "../../components/Loader/Loader";
import JobPage from "./JobPage";
import { useMediaQuery } from "@mui/material";
import JobDetailsRight from "./JobDetailsRight";
import { toast } from "react-toastify";
const StudentJobDetails = () => {
  const uniqueId = useParams();
  const navigate = useNavigate();
  const [job, setJob] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(false);
  const isNon1024 = useMediaQuery("(min-width:1024px)");
  const [savedIds, setSavedIds] = useState([]);
  const [data, setData] = useState(false);
  const [pop, setPop] = useState(false);

  const handleSaveJob = async () => {
    if (job) {
      await axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/saveJob`,
          { uniqueId: job.uniqueId },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((res) => {
          if (res.data.msg === "success") {
            setSavedIds(res.data.list);
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error("Failed to save job");
        });
    } else {
      toast.error("No job selected");
    }
  };

  const fetchJob = async () => {
    try {
      await axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/get/specific/job`,
          uniqueId,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((res) => {
          console.log(res.data);
          if (res.data.msg === "success") {
            setJob(res.data.job);
            setSavedIds(res.data.list);
          } else {
            navigate(-1);
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (uniqueId) {
      fetchJob();
    } else {
      navigate(-1);
    }
  }, []);

  const handleApply = async () => {
    setLoading2(true);
    await axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/applyjob`,
        { uniqueId: job?.uniqueId },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.msg === "success") {
          toast.success("Applied Successfully");
        } else if (res.data.msg === "Already applied") {
          toast.warning("Already Applied");
        } else {
          toast.error("Failed to apply");
        }
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Failed to apply");
      });
    setLoading2(false);
  };
  return (
    <div className="marginsleftrightCompany">
      <div className="center">
        <div className="headerCompany" style={{ padding: "30px 0px" }}>
          {loading ? (
            <div
              className="center align-items-center"
              style={{ height: "calc(100vh - 120px)" }}
            >
              <Loader />
            </div>
          ) : job ? (
            <div>
              <p className="studentHeading">Job Details</p>
              <p
                className="gray60011400"
                style={{
                  fontSize: "14px",
                  marginTop: "0.25rem",
                  marginBottom: "2rem",
                }}
              >
                Create events for students around the globe and attract talent.
              </p>
              <div
                className="d-flex"
                style={{
                  gap: "1.875rem",
                  flexDirection: isNon1024 ? "row" : "column",
                }}
              >
                <div>
                  <JobPage
                    job={job}
                    calculatedHeight={"100%"}
                    isNonMobile={isNon1024}
                    TRUE={true}
                    handleSaveJob={handleSaveJob}
                    savedIds={savedIds}
                    setData={setData}
                    pop={pop}
                    setPop={setPop}
                  />
                </div>

                <div
                  style={{
                    width: isNon1024 ? "300px" : "100%",
                    minWidth: isNon1024 ? "300px" : "100%",
                    maxWidth: isNon1024 ? "300px" : "100%",
                  }}
                >
                  <JobDetailsRight
                    job={job}
                    handleApply={handleApply}
                    loading2={loading2}
                    setPop={setPop}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div
              className="center align-items-center black14"
              style={{ height: "calc(100vh - 120px)" }}
            >
              No Job found
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default StudentJobDetails;
