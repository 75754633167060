import Chart from "../../assets/images/icons/Chart.png";
import Clock from "../../assets/images/icons/Clock.png";
import Clip from "../../assets/images/icons/Clip.png";
import Medal from "../../assets/images/icons/Medal.png";
import Book from "../../assets/images/icons/Book.png";
import Track from "../../assets/images/icons/Track.png";
import office from "../../assets/images/icons/office.png";
import videoicon from "../../assets/images/icons/videoicon.png";
import File from "../../assets/images/icons/File.png";
import { useState } from "react";
import vid from "../../assets/images/ani1.webm";
import Header from "./Header";
import "./main.css";
import SecondHeader from "./SecondHeader";
import img1 from "../../assets/images/solutionfirst.png";
import img2 from "../../assets/images/solutionsecond.png";
import img3 from "../../assets/images/solutionthird.png";
import FirstSection from "../../components/CompanyHome/FirstSection";
import { useMediaQuery } from "@mui/material";
import First from "./FirstSection";
import SecondSec from "./Second";
import Third from "./Third";
import SameSection from "./SameSection";
import NewSection from "../../components/CompanyHome/NewSection";
import Fourth from "./Fourth";
import Second from "../University/Toolkit/Second";

import HomeFooter from "../../components/Home/HomeFooter";
import Fifthnew from "./Fifth";
import Fifth from "../University/Home/Fifth";

const Solutions = () => {
  const [megaMenu, setMegaMenu] = useState(false);
  const isNon830 = useMediaQuery("(min-width:830px)");
  const isNon1024 = useMediaQuery("(min-width:1024px)");
  const isNon = useMediaQuery("(min-width:768px)");
  const isNon530 = useMediaQuery("(min-width:530px)");

  const items = [
    {
      icon: Clock,
      text: "Time constrained",
    },
    {
      icon: Track,
      text: "Track student performance",
    },
    {
      icon: Chart,
      text: "Track relative performance",
    },
    {
      icon: Book,
      text: "Add ressources",
    },
    {
      icon: Clip,
      text: "Attach job posts",
    },
    {
      icon: Medal,
      text: "Branded & graded certificate",
    },
  ];

  const virtualitems = [
    {
      icon: Clock,
      text: "Self-paced",
    },
    {
      icon: Track,
      text: "Self-assessed",
    },
    {
      icon: office,
      text: "Office tour",
    },
    {
      icon: videoicon,
      text: "Video reply from students",
    },
    {
      icon: Book,
      text: "Add ressources",
    },
    {
      icon: Clip,
      text: "Attach job posts",
    },
    {
      icon: Medal,
      text: "Branded certificate",
    },
  ];
  const insightitems = [
    {
      icon: Clock,
      text: "Self-paced",
    },
    {
      icon: Track,
      text: "Self-assessed",
    },
    {
      icon: office,
      text: "Office tour",
    },
    {
      icon: File,
      text: "File uploads from students",
    },
    {
      icon: Book,
      text: "Add ressources",
    },
    {
      icon: Clip,
      text: "Attach job posts",
    },
    {
      icon: Medal,
      text: "Branded certificate",
    },
  ];

  const samesections = [
    {
      icon: (
        <div
          className="center align-items-center"
          style={{
            padding: "8px",
            borderRadius: "50%",
            background: "rgba(227, 27, 84, 0.10)",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M3.87318 1.33301H12.1271C13.5298 1.33301 14.6668 2.47006 14.6668 3.87269V12.1267C14.6668 13.5293 13.5298 14.6663 12.1271 14.6663H3.87318C2.47055 14.6663 1.3335 13.5293 1.3335 12.1267V3.87269C1.3335 2.47006 2.47055 1.33301 3.87318 1.33301ZM12.1271 13.714C13.0038 13.714 13.7144 13.0033 13.7144 12.1267V3.87269C13.7144 2.99605 13.0038 2.28539 12.1271 2.28539H3.87318C2.99654 2.28539 2.28588 2.99605 2.28588 3.87269V12.1267C2.28588 13.0033 2.99654 13.714 3.87318 13.714H12.1271Z"
              fill="#E31B54"
            />
            <path
              d="M11.4922 5.93618H9.58746C9.32447 5.93618 9.11127 6.14938 9.11127 6.41237C9.11127 6.67537 9.32447 6.88856 9.58746 6.88856H10.1779L7.91762 9.14888L6.64778 7.87904C6.31081 7.54323 5.76571 7.54323 5.42873 7.87904L3.77159 9.48539C3.58591 9.6713 3.58591 9.97249 3.77159 10.1584C3.86041 10.2483 3.98171 10.2987 4.1081 10.2981C4.23463 10.2994 4.35621 10.2489 4.44461 10.1584L6.01286 8.59015L7.2827 9.85999C7.62185 10.1965 8.16895 10.1965 8.5081 9.85999L10.9906 7.37745V8.31713C10.9906 8.58013 11.2038 8.79332 11.4668 8.79332C11.7298 8.79332 11.943 8.58013 11.943 8.31713V6.41237C11.9402 6.16024 11.7438 5.95276 11.4922 5.93618Z"
              fill="#E31B54"
            />
          </svg>
        </div>
      ),
      title: "Fast-Track",
      description:
        "Create an assessment-style challenge by asking students multiple-choice questions. This allows students to prove their knowledge and better understand their performance relative to other students. ",
      img: img1,
      chips: items,
    },
    {
      img: img2,
      icon: (
        <div
          className="center align-items-center"
          style={{
            padding: "8px",
            borderRadius: "50%",
            background: "rgba(53,56,205, 0.10)",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M13.651 5.93972H14.3783C14.9711 5.94368 15.4507 6.42327 15.4546 7.01608V8.13608C15.4546 8.73054 14.9727 9.21245 14.3783 9.21245H13.8183V9.84517C13.8635 10.9527 13.0055 11.8886 11.8983 11.9397H10.7492C10.2223 11.932 9.72315 11.7022 9.37464 11.307C9.03332 10.9013 8.53025 10.6672 8.0001 10.6672C7.46995 10.6672 6.96687 10.9013 6.62555 11.307C6.27705 11.7022 5.77789 11.932 5.25101 11.9397H4.10192C2.99468 11.8886 2.13672 10.9527 2.18192 9.84517V9.21245H1.60737C1.01291 9.21245 0.531006 8.73054 0.531006 8.13608V7.01608C0.534971 6.42327 1.01456 5.94368 1.60737 5.93972H2.33464C2.6046 5.19139 3.30661 4.68583 4.10192 4.66699H11.8983C12.6882 4.69173 13.383 5.19623 13.651 5.93972ZM1.63646 7.01608V8.12154L2.18192 8.11426V7.01608H1.63646ZM12.7274 9.84517C12.7698 10.3495 12.4015 10.7952 11.8983 10.8488H10.7492C10.5317 10.8474 10.3262 10.7486 10.1892 10.5797C9.63695 9.95354 8.84228 9.59481 8.00737 9.59481C7.17246 9.59481 6.37779 9.95354 5.82555 10.5797C5.68852 10.7486 5.48307 10.8474 5.26555 10.8488H4.10191C3.59869 10.7952 3.23044 10.3495 3.27282 9.84517V6.76154C3.23044 6.25725 3.59869 5.81147 4.10191 5.7579H11.8983C12.4015 5.81147 12.7698 6.25725 12.7274 6.76154V9.84517ZM13.8183 7.02336V8.12154L14.3637 8.12881V7.02336H13.8183Z"
              fill="#3538CD"
            />
            <path
              d="M6.54555 7.53245H4.36373C4.06249 7.53245 3.81828 7.77665 3.81828 8.0779C3.81828 8.37915 4.06249 8.62336 4.36373 8.62336H6.54555C6.8468 8.62336 7.09101 8.37915 7.09101 8.0779C7.09101 7.77665 6.8468 7.53245 6.54555 7.53245Z"
              fill="#3538CD"
            />
            <path
              d="M10.6401 7.69245C10.5886 7.63916 10.5267 7.59707 10.4583 7.56881C10.3248 7.51791 10.1772 7.51791 10.0437 7.56881C9.97526 7.59707 9.91337 7.63916 9.86191 7.69245C9.76357 7.79666 9.70891 7.93461 9.70919 8.0779C9.70636 8.14762 9.71877 8.21711 9.74555 8.28154C9.77296 8.34872 9.81239 8.41033 9.86191 8.46336C9.96763 8.56482 10.1081 8.62205 10.2546 8.62336C10.3248 8.62279 10.3941 8.60794 10.4583 8.57972C10.5255 8.55231 10.5871 8.51288 10.6401 8.46336C10.7411 8.36029 10.7984 8.2222 10.8001 8.0779C10.8003 8.00533 10.7855 7.93351 10.7565 7.86699C10.728 7.80254 10.6887 7.74348 10.6401 7.69245Z"
              fill="#3538CD"
            />
            <path
              d="M9.00373 7.57608C8.87024 7.52519 8.72268 7.52519 8.58919 7.57608C8.52179 7.60244 8.46187 7.64489 8.41464 7.69972C8.31093 7.80098 8.25312 7.94023 8.25464 8.08517C8.25551 8.15459 8.2678 8.22339 8.29101 8.28881C8.31848 8.3577 8.36068 8.41975 8.41464 8.47063C8.51684 8.57295 8.65548 8.6305 8.8001 8.63063C8.87023 8.63006 8.93952 8.61522 9.00373 8.58699C9.07091 8.55959 9.13253 8.52015 9.18555 8.47063C9.28531 8.36674 9.34242 8.22917 9.34555 8.08517C9.34542 7.94056 9.28787 7.80191 9.18555 7.69972C9.1341 7.64643 9.0722 7.60434 9.00373 7.57608Z"
              fill="#3538CD"
            />
            <path
              d="M12.0946 7.68517C11.9369 7.5308 11.7025 7.48506 11.4983 7.56881C11.4326 7.59896 11.3714 7.63813 11.3165 7.68517C11.2688 7.73965 11.2296 7.80093 11.2001 7.86699C11.1862 7.93416 11.1862 8.00346 11.2001 8.07063C11.1856 8.14018 11.1856 8.21198 11.2001 8.28154C11.2277 8.34645 11.2672 8.40564 11.3165 8.45608C11.3673 8.51005 11.4294 8.55224 11.4983 8.57972C11.5648 8.60872 11.6366 8.62358 11.7092 8.62336C11.7794 8.62365 11.8489 8.60877 11.9128 8.57972C11.9817 8.55224 12.0438 8.51005 12.0946 8.45608C12.1439 8.40564 12.1834 8.34645 12.211 8.28154C12.2342 8.21361 12.2465 8.14242 12.2474 8.07063C12.2459 8.00127 12.2336 7.93257 12.211 7.86699C12.1792 7.8022 12.1401 7.74121 12.0946 7.68517Z"
              fill="#3538CD"
            />
          </svg>
        </div>
      ),
      title: "Virtual Experience",
      description:
        "Create an immersive experience by a point-of-view styled challenge sequence in which students “walk” through your offices to receive the challenge brief from you and to work on tasks. Students get the full experience of their future work.",
      chips: virtualitems,
    },
    {
      img: img3,
      icon: (
        <div
          className="center align-items-center"
          style={{
            padding: "8px",
            borderRadius: "50%",
            background: "rgba(252, 131, 9, 0.10)",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              d="M7.96594 5.66634C7.6913 5.66275 7.46953 5.44098 7.46594 5.16634V1.83301C7.46594 1.55687 7.6898 1.33301 7.96594 1.33301C8.24208 1.33301 8.46594 1.55687 8.46594 1.83301V5.16634C8.46235 5.44098 8.24058 5.66275 7.96594 5.66634Z"
              fill="#FC7E00"
            />
            <path
              d="M7.96594 14.9997C7.6913 14.9961 7.46953 14.7743 7.46594 14.4997V11.1663C7.46594 10.8902 7.6898 10.6663 7.96594 10.6663C8.24208 10.6663 8.46594 10.8902 8.46594 11.1663V14.4997C8.46235 14.7743 8.24058 14.9961 7.96594 14.9997Z"
              fill="#FC7E00"
            />
            <path
              d="M6.24594 6.20634C6.08146 6.20755 5.92691 6.12778 5.83261 5.99301L3.92594 3.26634C3.84845 3.1578 3.81753 3.02279 3.84006 2.89133C3.8626 2.75988 3.93672 2.64288 4.04594 2.56634C4.2678 2.43154 4.55565 2.48623 4.71261 2.69301L6.63261 5.41967C6.74449 5.57089 6.76065 5.77256 6.67427 5.93966C6.58789 6.10676 6.41401 6.21019 6.22594 6.20634H6.24594Z"
              fill="#FC7E00"
            />
            <path
              d="M11.5993 13.853C11.4348 13.8542 11.2802 13.7744 11.1859 13.6397L9.29928 10.913C9.14096 10.6866 9.19617 10.3747 9.42261 10.2163C9.64905 10.058 9.96096 10.1132 10.1193 10.3397L12.0259 13.0663C12.1378 13.2176 12.154 13.4192 12.0676 13.5863C11.9812 13.7534 11.8073 13.8569 11.6193 13.853H11.5993Z"
              fill="#FC7E00"
            />
            <path
              d="M5.14594 7.63967C5.08853 7.64889 5.03002 7.64889 4.97261 7.63967L1.84594 6.47301C1.71596 6.42673 1.61048 6.32944 1.55386 6.20361C1.49724 6.07779 1.49438 5.93432 1.54594 5.80634C1.5903 5.67509 1.68781 5.56853 1.81462 5.51274C1.94144 5.45694 2.08587 5.45704 2.21261 5.51301L5.29928 6.67301C5.52312 6.75906 5.65434 6.99191 5.61202 7.22796C5.56969 7.46401 5.36574 7.63676 5.12594 7.63967H5.14594Z"
              fill="#FC7E00"
            />
            <path
              d="M13.9193 10.833C13.86 10.8316 13.8015 10.8203 13.7459 10.7997L10.6326 9.65967C10.5014 9.61502 10.3946 9.51791 10.3378 9.39153C10.2809 9.26514 10.279 9.12082 10.3326 8.99301C10.3789 8.86303 10.4762 8.75754 10.602 8.70092C10.7278 8.6443 10.8713 8.64144 10.9993 8.69301L14.1259 9.83301C14.2559 9.87929 14.3614 9.97658 14.418 10.1024C14.4746 10.2282 14.4775 10.3717 14.4259 10.4997C14.3514 10.7122 14.144 10.8487 13.9193 10.833Z"
              fill="#FC7E00"
            />
            <path
              d="M1.84594 10.3063C1.58967 10.3128 1.37001 10.1244 1.33736 9.87017C1.30472 9.6159 1.46967 9.37813 1.71928 9.31967L4.93928 8.45967C5.06747 8.42505 5.20417 8.44285 5.31923 8.50913C5.43429 8.57542 5.51825 8.68474 5.55261 8.81301C5.62084 9.07947 5.46401 9.3517 5.19928 9.42634L1.96594 10.2863C1.927 10.2983 1.88665 10.305 1.84594 10.3063Z"
              fill="#FC7E00"
            />
            <path
              d="M10.8659 7.89301C10.6383 7.89205 10.4392 7.73933 10.3793 7.51967C10.3447 7.39148 10.3624 7.25478 10.4287 7.13972C10.495 7.02467 10.6043 6.9407 10.7326 6.90634L13.9659 6.04634C14.2329 5.97454 14.5075 6.13274 14.5793 6.39967C14.6511 6.66661 14.4929 6.94121 14.2259 7.01301L11.0059 7.87301C10.9607 7.88754 10.9134 7.8943 10.8659 7.89301Z"
              fill="#FC7E00"
            />
            <path
              d="M3.89261 13.4997C3.69938 13.4981 3.52438 13.3852 3.44313 13.2099C3.36188 13.0346 3.38893 12.8281 3.51261 12.6797L5.65261 10.1663C5.83427 9.96068 6.14555 9.93425 6.35928 10.1063C6.46129 10.1919 6.52499 10.3145 6.53625 10.4472C6.54751 10.5798 6.50541 10.7115 6.41928 10.813L4.27928 13.3397C4.18051 13.4477 4.03885 13.5064 3.89261 13.4997Z"
              fill="#FC7E00"
            />
            <path
              d="M9.89261 6.36634C9.7757 6.3688 9.66191 6.3285 9.57261 6.25301C9.4706 6.16748 9.4069 6.0448 9.39564 5.91215C9.38438 5.77951 9.42648 5.64784 9.51261 5.54634L11.6526 2.99301C11.7381 2.89099 11.8608 2.8273 11.9935 2.81604C12.1261 2.80477 12.2578 2.84687 12.3593 2.93301C12.55 3.1056 12.5762 3.3958 12.4193 3.59967L10.2793 6.16634C10.1864 6.28706 10.0448 6.36033 9.89261 6.36634Z"
              fill="#FC7E00"
            />
            <path
              d="M7.96594 5.66634C7.6913 5.66275 7.46953 5.44098 7.46594 5.16634V1.83301C7.46594 1.55687 7.6898 1.33301 7.96594 1.33301C8.24208 1.33301 8.46594 1.55687 8.46594 1.83301V5.16634C8.46235 5.44098 8.24058 5.66275 7.96594 5.66634Z"
              fill="#FC7E00"
            />
            <path
              d="M7.96594 14.9997C7.6913 14.9961 7.46953 14.7743 7.46594 14.4997V11.1663C7.46594 10.8902 7.6898 10.6663 7.96594 10.6663C8.24208 10.6663 8.46594 10.8902 8.46594 11.1663V14.4997C8.46235 14.7743 8.24058 14.9961 7.96594 14.9997Z"
              fill="#FC7E00"
            />
            <path
              d="M6.24594 6.20634C6.08146 6.20755 5.92691 6.12778 5.83261 5.99301L3.92594 3.26634C3.84845 3.1578 3.81753 3.02279 3.84006 2.89133C3.8626 2.75988 3.93672 2.64288 4.04594 2.56634C4.2678 2.43154 4.55565 2.48623 4.71261 2.69301L6.63261 5.41967C6.74449 5.57089 6.76065 5.77256 6.67427 5.93966C6.58789 6.10676 6.41401 6.21019 6.22594 6.20634H6.24594Z"
              fill="#FC7E00"
            />
            <path
              d="M11.5993 13.853C11.4348 13.8542 11.2802 13.7744 11.1859 13.6397L9.29928 10.913C9.14096 10.6866 9.19617 10.3747 9.42261 10.2163C9.64905 10.058 9.96096 10.1132 10.1193 10.3397L12.0259 13.0663C12.1378 13.2176 12.154 13.4192 12.0676 13.5863C11.9812 13.7534 11.8073 13.8569 11.6193 13.853H11.5993Z"
              fill="#FC7E00"
            />
            <path
              d="M5.14594 7.63967C5.08853 7.64889 5.03002 7.64889 4.97261 7.63967L1.84594 6.47301C1.71596 6.42673 1.61048 6.32944 1.55386 6.20361C1.49724 6.07779 1.49438 5.93432 1.54594 5.80634C1.5903 5.67509 1.68781 5.56853 1.81462 5.51274C1.94144 5.45694 2.08587 5.45704 2.21261 5.51301L5.29928 6.67301C5.52312 6.75906 5.65434 6.99191 5.61202 7.22796C5.56969 7.46401 5.36574 7.63676 5.12594 7.63967H5.14594Z"
              fill="#FC7E00"
            />
            <path
              d="M13.9193 10.833C13.86 10.8316 13.8015 10.8203 13.7459 10.7997L10.6326 9.65967C10.5014 9.61502 10.3946 9.51791 10.3378 9.39153C10.2809 9.26514 10.279 9.12082 10.3326 8.99301C10.3789 8.86303 10.4762 8.75754 10.602 8.70092C10.7278 8.6443 10.8713 8.64144 10.9993 8.69301L14.1259 9.83301C14.2559 9.87929 14.3614 9.97658 14.418 10.1024C14.4746 10.2282 14.4775 10.3717 14.4259 10.4997C14.3514 10.7122 14.144 10.8487 13.9193 10.833Z"
              fill="#FC7E00"
            />
            <path
              d="M1.84594 10.3063C1.58967 10.3128 1.37001 10.1244 1.33736 9.87017C1.30472 9.6159 1.46967 9.37813 1.71928 9.31967L4.93928 8.45967C5.06747 8.42505 5.20417 8.44285 5.31923 8.50913C5.43429 8.57542 5.51825 8.68474 5.55261 8.81301C5.62084 9.07947 5.46401 9.3517 5.19928 9.42634L1.96594 10.2863C1.927 10.2983 1.88665 10.305 1.84594 10.3063Z"
              fill="#FC7E00"
            />
            <path
              d="M10.8659 7.89301C10.6383 7.89205 10.4392 7.73933 10.3793 7.51967C10.3447 7.39148 10.3624 7.25478 10.4287 7.13972C10.495 7.02467 10.6043 6.9407 10.7326 6.90634L13.9659 6.04634C14.2329 5.97454 14.5075 6.13274 14.5793 6.39967C14.6511 6.66661 14.4929 6.94121 14.2259 7.01301L11.0059 7.87301C10.9607 7.88754 10.9134 7.8943 10.8659 7.89301Z"
              fill="#FC7E00"
            />
            <path
              d="M3.89261 13.4997C3.69938 13.4981 3.52438 13.3852 3.44313 13.2099C3.36188 13.0346 3.38893 12.8281 3.51261 12.6797L5.65261 10.1663C5.83427 9.96068 6.14555 9.93425 6.35928 10.1063C6.46129 10.1919 6.52499 10.3145 6.53625 10.4472C6.54751 10.5798 6.50541 10.7115 6.41928 10.813L4.27928 13.3397C4.18051 13.4477 4.03885 13.5064 3.89261 13.4997Z"
              fill="#FC7E00"
            />
            <path
              d="M9.89261 6.36634C9.7757 6.3688 9.66191 6.3285 9.57261 6.25301C9.4706 6.16748 9.4069 6.0448 9.39564 5.91215C9.38438 5.77951 9.42648 5.64784 9.51261 5.54634L11.6526 2.99301C11.7381 2.89099 11.8608 2.8273 11.9935 2.81604C12.1261 2.80477 12.2578 2.84687 12.3593 2.93301C12.55 3.1056 12.5762 3.3958 12.4193 3.59967L10.2793 6.16634C10.1864 6.28706 10.0448 6.36033 9.89261 6.36634Z"
              fill="#FC7E00"
            />
          </svg>
        </div>
      ),
      title: "Insights",
      description:
        "Students gain insights by self-assessed challenges created by your team or current interns. Tasks such as “write an e-mail to client XYZ” or “prepare a presentation for..” help students to better understand the daily work routine.",
      chips: insightitems,
    },
  ];

  return (
    <div
      style={{
        maxWidth: "100vw",
        minWidth: "100vw",

        // overflow: "hidden",
      }}
    >
      <Header isNon1024={isNon1024} isNon830={isNon830} isNon530={isNon530} />
      <div style={{ position: "relative" }}>
        <SecondHeader
          text={"Challenges"}
          setMegaMenu={setMegaMenu}
          megaMenu={megaMenu}
          isNon={isNon}
          isNon530={isNon530}
        />
        <First isNon530={isNon530} />
        <SecondSec classes={"secondsectionsolutions"} vid={vid} />
        <Third />
        <div style={{ background: "rgba(242, 244, 247, 0.8)" }}>
          <div className={`marginsleftrightCompany `}>
            {samesections.map((same, index) => (
              <div key={index}>
                <SameSection
                  isNonMobile1000={isNon1024}
                  icon={same.icon}
                  title={same.title}
                  description={same.description}
                  chips={same.chips}
                  img={same?.img}
                />
              </div>
            ))}
            <div
              style={{
                paddingTop: "130px",
                paddingBottom: "130px",
              }}
            >
              <NewSection />
            </div>
          </div>
        </div>
        <Fourth isNon={isNon} />
        <Fifthnew />
        <Second isNon={isNon} />
        <div
          style={{
            paddingTop: "12.125rem",
          }}
        >
          <Fifth TRUE={true} />
        </div>
        <div className="marginsleftrightCompany mt-5 pt-5">
          {/* <FourthSection /> */}
          <div className="d-flex" style={{ justifyContent: "center" }}>
            <div className="headerCompany">
              <HomeFooter />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Solutions;
