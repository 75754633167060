import CloseIcon from "@mui/icons-material/Close";
import PhotoSizeSelectActualOutlinedIcon from "@mui/icons-material/PhotoSizeSelectActualOutlined";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import { useRef, useState } from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { toast } from "react-toastify";
import SelectedBox from "../../assets/images/icons/selectedbox";
import UnselectedBox from "../../assets/images/icons/unselectedBox";
import GroupCategory from "./GroupCategories";
import InterestItems from "../../components/InterestItems/InterestItems";
import axios from "axios";
const CreateGroup = ({ setPopup, setGroups }) => {
  const [banner, setBanner] = useState(null);
  const [profilePhoto, setProfilePhoto] = useState(null);
  const [title, setTitle] = useState("");
  const [about, setAbout] = useState("");
  const [type, setType] = useState("");
  const [rules, setRules] = useState("");
  const [categories, setCategories] = useState([]);
  const [selectedItem, setSelectedItem] = useState([]);
  const [permissions, setPermissions] = useState({
    postReviewByAdmin: false,
    allowMembersToInvite: false,
  });

  const [loading, setLoading] = useState(false);

  const fileInputRef = useRef(null);
  const fileInputRef2 = useRef(null);

  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const handleClick2 = () => {
    if (fileInputRef2.current) {
      fileInputRef2.current.click();
    }
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    const allowedTypes = [
      "image/svg+xml",
      "image/png",
      "image/jpeg",
      "image/gif",
    ];
    const maxFileSize = 20 * 1024 * 1024; // 12MB in bytes

    if (selectedFile) {
      if (allowedTypes.includes(selectedFile.type)) {
        if (selectedFile.size <= maxFileSize) {
          setBanner(selectedFile);
        } else {
          toast.error("File size exceeds 20MB limit");
        }
      } else {
        toast.error(
          "Invalid file type. Only SVG, PNG, JPEG, and GIF are allowed."
        );
      }
    }
  };

  const grouptypes = ["Only for my University", "Private", "Public"];

  const handleFileChange2 = (e) => {
    const selectedFile = e.target.files[0];
    const allowedTypes = [
      "image/svg+xml",
      "image/png",
      "image/jpeg",
      "image/gif",
    ];
    const maxFileSize = 20 * 1024 * 1024; // 12MB in bytes

    if (selectedFile) {
      if (allowedTypes.includes(selectedFile.type)) {
        if (selectedFile.size <= maxFileSize) {
          setProfilePhoto(selectedFile);
        } else {
          toast.error("File size exceeds 20MB limit");
        }
      } else {
        toast.error(
          "Invalid file type. Only SVG, PNG, JPEG, and GIF are allowed."
        );
      }
    }
  };

  const handleCreateGroup = async () => {
    if (!profilePhoto) {
      return toast.error("Please provide Group profile photo");
    } else if (!title) {
      return toast.error("Please Enter Group Name");
    } else if (!type) {
      return toast.error("Please Select Group Type");
    } else if (!rules) {
      return toast.error("Please Enter Group Rules");
    } else if (categories.length === 0 || selectedItem.length === 0) {
      return toast.error("Please Select Group Categories");
    } else {
      setLoading(true);
      const formData = new FormData();
      formData.append("banner", banner);
      formData.append("profilePhoto", profilePhoto);
      formData.append("title", title);
      formData.append("about", about);
      formData.append("type", type);
      formData.append("rules", rules);
      formData.append("categories", categories);
      formData.append("selectedItem", selectedItem);
      formData.append("permissions", permissions);

      await axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/api/create/group`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((res) => {
          if (res.data.msg === "success") {
            toast.success("Group Created Successfully");
            setPopup(false);
            setGroups((prev) => {
              return [res.data.newgroup, ...prev];
            });
          } else {
            toast.error("Error creating Group");
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error("Error creating Group");
        });
      setLoading(false);
    }
  };

  return (
    <div
      className="center align-items-center"
      style={{
        position: "fixed",
        height: "100vh",
        width: "100vw",
        top: 0,
        left: 0,
        background: "rgba(0,0,0,0.2)",
      }}
      onClick={() => {
        setPopup(false);
      }}
    >
      <div
        style={{
          background: "#FFF",

          borderRadius: "12px",
          maxWidth: "715px",
          width: "100%",
          maxHeight: "70vh",
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div
          className="space align-items-center"
          style={{ padding: "1.5rem", borderBottom: "1px solid #EAECF0" }}
        >
          <p
            className="gray90018500"
            style={{
              fontSize: "20px",
              fontWeight: 400,
              fontFamily: "FilmotypeFashion",
            }}
          >
            Create Group
          </p>
          <CloseIcon
            className="hoverdarkblacksvg"
            style={{ cursor: "pointer" }}
            disabled={loading}
            onClick={() => {
              setPopup(false);
            }}
          />
        </div>
        <div
          className="tablescroll"
          style={{
            padding: "1.5rem",
            overflowY: "auto",
            maxHeight: "50vh",
            marginRight: "4px",
          }}
        >
          <div
            className="center align-items-center"
            style={{
              background: "#F2F4F7",
              height: "130px",
              borderRadius: "5px 5px 0px 0px",
              position: "relative",
            }}
          >
            {banner ? (
              <img
                src={
                  banner && banner instanceof File
                    ? URL.createObjectURL(banner)
                    : banner.includes("http")
                    ? banner
                    : `${process.env.REACT_APP_BACKEND_URL}/${banner}`
                }
                alt="Selected file"
                style={{
                  objectFit: "cover",
                  maxHeight: "130px",
                  width: "100%",
                  borderRadius: "5px 5px 0px 0px",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              />
            ) : (
              <PhotoSizeSelectActualOutlinedIcon
                style={{ color: "#D0D5DD", width: "80px", height: "62px" }}
              />
            )}

            <input
              type="file"
              accept="image/svg+xml,image/png,image/jpeg,image/gif"
              onChange={handleFileChange}
              style={{ display: "none" }}
              ref={fileInputRef}
            />
            <div
              style={{
                position: "absolute",
                top: "0.75rem",
                right: "0.75rem",
                background: "#FFF",
                borderRadius: "50%",
                border: "1px solid #EAECF0",
                padding: "6px",
                cursor: "pointer",
              }}
              onClick={handleClick}
            >
              <CameraAltOutlinedIcon style={{ color: "#667085" }} />
            </div>
          </div>
          <div className="d-flex">
            <div
              style={{
                margin: "0rem 1rem",
                padding: profilePhoto ? "0px" : "14px",
                borderRadius: "50%",
                border: "2px solid #FFF",
                background: "#F2F4F7",
                position: "relative",
                marginTop: "-39px",
              }}
            >
              {profilePhoto ? (
                <img
                  src={
                    profilePhoto && profilePhoto instanceof File
                      ? URL.createObjectURL(profilePhoto)
                      : profilePhoto.includes("http")
                      ? profilePhoto
                      : `${process.env.REACT_APP_BACKEND_URL}/${profilePhoto}`
                  }
                  alt="Selected file"
                  style={{
                    objectFit: "cover",
                    maxHeight: "80px",
                    width: "80px",
                    borderRadius: "50%",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                />
              ) : (
                <PhotoSizeSelectActualOutlinedIcon
                  style={{ color: "#D0D5DD", width: "46px", height: "46px" }}
                />
              )}
              <input
                type="file"
                accept="image/svg+xml,image/png,image/jpeg,image/gif"
                onChange={handleFileChange2}
                style={{ display: "none" }}
                ref={fileInputRef2}
              />
              <div
                className="center align-item-center"
                style={{
                  position: "absolute",
                  bottom: 0,
                  right: 0,
                  background: "#FFF",
                  borderRadius: "50%",
                  border: "1px solid #EAECF0",
                  padding: "6px",
                  cursor: "pointer",
                  height: "28px",
                  width: "28px",
                }}
                onClick={handleClick2}
              >
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none">
                  <path
                    d="M6.41699 1.16669H5.25033C2.33366 1.16669 1.16699 2.33335 1.16699 5.25002V8.75002C1.16699 11.6667 2.33366 12.8334 5.25033 12.8334H8.75033C11.667 12.8334 12.8337 11.6667 12.8337 8.75002V7.58335"
                    stroke="#667085"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M9.35708 1.76169L4.76042 6.35836C4.58542 6.53336 4.41042 6.87753 4.37542 7.12836L4.12458 8.88419C4.03125 9.52002 4.48042 9.96336 5.11625 9.87586L6.87208 9.62503C7.11708 9.59003 7.46125 9.41503 7.64208 9.24003L12.2388 4.64336C13.0321 3.85003 13.4054 2.92836 12.2388 1.76169C11.0721 0.595025 10.1504 0.968359 9.35708 1.76169Z"
                    stroke="#667085"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M8.69727 2.42084C9.0881 3.815 10.1789 4.90584 11.5789 5.3025"
                    stroke="#667085"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div className="mt-3 d-flex flex-column" style={{ gap: "1.5rem" }}>
            <div className="d-flex flex-column" style={{ gap: "0.75rem" }}>
              <label
                htmlFor="groupname"
                className="gray95014400"
                style={{ fontWeight: 500 }}
              >
                Group Name*
              </label>
              <input
                className="newinputs"
                id="groupname"
                value={title}
                maxLength={60}
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
              />
            </div>
            <div className="d-flex flex-column" style={{ gap: "0.75rem" }}>
              <label
                htmlFor="aboutgroup"
                className="gray95014400"
                style={{ fontWeight: 500 }}
              >
                About Group
              </label>
              <textarea
                className="newinputs"
                id="aboutgroup"
                value={about}
                maxLength={1000}
                onChange={(e) => {
                  setAbout(e.target.value);
                }}
                style={{ minHeight: "120px", resize: "none" }}
              />
            </div>
            <div className="d-flex flex-column" style={{ gap: "0.75rem" }}>
              <label
                htmlFor="grouptype"
                className="gray95014400"
                style={{ fontWeight: 500 }}
              >
                Group Type
              </label>
              <div className="d-flex flex-wrap gap-3">
                {grouptypes.map((grp, index) => (
                  <div
                    className="d-flex gap-2 align-items-center"
                    key={index}
                    style={{ cursor: "pointer" }}
                    onClick={() => setType(grp)}
                  >
                    <div
                      className="d-flex align-items-center"
                      style={{ minWidth: "16px" }}
                    >
                      {type === grp ? <SelectedBox /> : <UnselectedBox />}
                    </div>
                    <p className="gray80014400">{grp}</p>
                  </div>
                ))}
              </div>
              <div
                className="mt-3 d-flex gray80014400 align-items-center"
                style={{ padding: "12px", background: "#F9FAFB", gap: "10px" }}
              >
                <InfoOutlinedIcon style={{ height: "16px", width: "16px" }} />
                Group type can't be changed once it's created.
              </div>
            </div>
            <div className="d-flex flex-column" style={{ gap: "0.75rem" }}>
              <label
                htmlFor="grouprule"
                className="gray95014400"
                style={{ fontWeight: 500 }}
              >
                Group Rules
              </label>
              <textarea
                className="newinputs"
                id="grouprule"
                value={rules}
                maxLength={1000}
                onChange={(e) => {
                  setRules(e.target.value);
                }}
                style={{ minHeight: "120px", resize: "none" }}
              />
            </div>
            <div className="d-flex flex-column" style={{ gap: "0.75rem" }}>
              <label className="gray95014400" style={{ fontWeight: 500 }}>
                Group Category
              </label>
              <GroupCategory
                categories={categories}
                setCategories={setCategories}
              />
            </div>
            <div className="d-flex flex-column" style={{ gap: "0.75rem" }}>
              <label className="gray95014400" style={{ fontWeight: 500 }}>
                Career Retaled Categories
              </label>
              <InterestItems
                selectedItem={selectedItem}
                setSelectedItem={setSelectedItem}
                group={true}
                TRUE={true}
              />
            </div>
            <hr />
            <div className="d-flex flex-column" style={{ gap: "0.75rem" }}>
              <label className="gray95014400" style={{ fontWeight: 500 }}>
                Discoverability
              </label>
              <p className="gray60011400" style={{ fontSize: "14px" }}>
                Public groups appear in search results and are visible to others
                on members’ profiles.
              </p>
            </div>
            <div className="d-flex flex-column" style={{ gap: "0.75rem" }}>
              <label className="gray95014400" style={{ fontWeight: 500 }}>
                Permissions
              </label>
              <div
                className="d-flex gap-2"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setPermissions((prev) => {
                    return {
                      ...prev,
                      allowMembersToInvite: !prev.allowMembersToInvite,
                    };
                  });
                }}
              >
                <div
                  className=""
                  style={{ minWidth: "20px", maxWidth: "16px" }}
                >
                  {permissions.allowMembersToInvite ? (
                    <SelectedBox />
                  ) : (
                    <UnselectedBox />
                  )}
                </div>
                <div>
                  <p className="gray90014400">
                    Allow members to invite their connections
                  </p>
                  <p
                    className="gray60011400"
                    style={{ marginTop: "6px", fontSize: "14px" }}
                  >
                    Group members can invite 1st degree connections to the
                    group. All requests to join will still require admin
                    approval.
                  </p>
                </div>
              </div>
              <div
                className="d-flex gap-2"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setPermissions((prev) => {
                    return {
                      ...prev,
                      postReviewByAdmin: !prev.postReviewByAdmin,
                    };
                  });
                }}
              >
                <div style={{ minWidth: "16px", maxWidth: "16px" }}>
                  {permissions.postReviewByAdmin ? (
                    <SelectedBox />
                  ) : (
                    <UnselectedBox />
                  )}
                </div>
                <div>
                  <p className="gray90014400">
                    Require new posts to be reviewed by admins
                  </p>
                  <p
                    className="gray60011400"
                    style={{ marginTop: "6px", fontSize: "14px" }}
                  >
                    Member's posts will require admin approval within 14 days
                    before they become visible to others.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="d-flex justify-content-end"
          style={{ background: "#FFF" }}
        >
          <div className="d-flex gap-3 " style={{ padding: "1.5rem" }}>
            <button
              className="backbutton"
              disabled={loading}
              onClick={() => setPopup(false)}
            >
              Cancel
            </button>
            <button
              className="continuebutton"
              onClick={() => {
                handleCreateGroup();
              }}
              disabled={loading}
            >
              Create Group
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateGroup;
