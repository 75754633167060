import { useState } from "react";
import { useMediaQuery } from "@mui/material";
import Header from "../Header";
import SecondHeader from "../SecondHeader";
import First from "./First";
import SecondSec from "../Second";
import vid from "../../../assets/images/ani3.webm";
import Third from "./Third";
import EventTypes from "../Events/EventsTypes";
import image1 from "../../../assets/images/analytics1.png";
import SameSection from "../SameSection";
import Fifthnew from "../Fifth";
import Second from "../../University/Toolkit/Second";
import Fifth from "../../University/Home/Fifth";
import HomeFooter from "../../../components/Home/HomeFooter";
const AnalyticsHome = () => {
  const [megaMenu, setMegaMenu] = useState(true);
  const isNon830 = useMediaQuery("(min-width:830px)");
  const isNon1024 = useMediaQuery("(min-width:1024px)");
  const isNon = useMediaQuery("(min-width:768px)");
  const isNon530 = useMediaQuery("(min-width:530px)");

  const samesections = [
    {
      icon: <EventTypes type={"Live Stream"} TRUE={true} />,
      title: "How is student engagement analyzed?",
      description:
        "Live events offer a unique opportunity to enrich the candidate journey. By hosting interactive sessions, you can connect with early talent. Showcase your company culture, job opportunities, application process and other insights. ",
      img: image1,
      chips: [],
    },
  ];

  return (
    <div
      style={{
        maxWidth: "100vw",
        minWidth: "100vw",
      }}
    >
      <Header isNon1024={isNon1024} isNon530={isNon530} isNon830={isNon830} />
      <div style={{ position: "relative" }}>
        <SecondHeader
          text={"Analytics"}
          setMegaMenu={setMegaMenu}
          megaMenu={megaMenu}
          isNon={isNon}
          isNon530={isNon530}
        />
        <First isNon530={isNon530} />
        <SecondSec classes={"secondAnalyticsHome"} vid={vid} />
        <Third />
        <div style={{ background: "rgba(242, 244, 247, 0.8)" }}>
          <div className={`marginsleftrightCompany `}>
            {samesections.map((same, index) => (
              <div key={index}>
                <SameSection
                  isNonMobile1000={isNon1024}
                  icon={same.icon}
                  title={same.title}
                  description={same.description}
                  chips={same.chips}
                  img={same?.img}
                />
              </div>
            ))}
          </div>
        </div>
        <Fifthnew />
        <Second isNon={isNon} />
        <div
          style={{
            paddingTop: "12.125rem",
          }}
        >
          <Fifth TRUE={true} />
        </div>
        <div className="marginsleftrightCompany mt-5 pt-5">
          {/* <FourthSection /> */}
          <div className="d-flex" style={{ justifyContent: "center" }}>
            <div className="headerCompany">
              <HomeFooter />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnalyticsHome;
