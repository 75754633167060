import PostingTab from "./PostingTab";
import { useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import axios from "axios";

import AllPost from "./AllPosts";

const SocialMain = ({ user }) => {
  const [posts, setPosts] = useState([]);
  const isNonMobile1024 = useMediaQuery("(min-width:1024px)");
  const isNonMobile450 = useMediaQuery("(min-width:450px)");
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);

  console.log(posts);

  const fetchPosts = async () => {
    if (loading || !localStorage.getItem("token") || !hasMore) return;
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/get/posts`,
        { page, limit: 15 },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.data.msg === "success") {
        const newPosts = response.data.posts;
        setPosts((prevPosts) => [...prevPosts, ...newPosts]);

        if (newPosts.length < 15) {
          setHasMore(false); // No more posts if less than 15 are returned
        }
      } else {
        setHasMore(false);
      }
    } catch (error) {
      console.error("Error fetching posts:", error);
      setHasMore(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPosts();
  }, [page]); // Trigger when fetchPosts changes

  const fetchMorePosts = () => {
    if (!loading && hasMore) {
      setPage((prevPage) => prevPage + 1); // Increment page number to fetch more posts
    }
  };

  return (
    <div className="marginsleftrightCompany">
      {user && (
        <div className="center">
          <div className="headerCompany">
            <div className="" style={{ paddingTop: "1.5rem" }}>
              <div style={{ gap: "1.5rem" }} className="d-flex">
                <div style={{ width: isNonMobile1024 ? "68.94%" : "100%" }}>
                  <PostingTab
                    photo={user?.profilePhoto}
                    isNonMobile450={isNonMobile450}
                    isNonMobile1024={isNonMobile1024}
                    setPosts={setPosts}
                    hasMore={hasMore}
                  />
                  <div style={{ marginTop: "1.25rem" }}>
                    {posts && (
                      <AllPost
                        posts={posts}
                        fetchMorePosts={fetchMorePosts}
                        hasMore={hasMore}
                        isNonMobile450={isNonMobile450}
                        isNonMobile1024={isNonMobile1024}
                        user={user}
                        setPosts={setPosts}
                      />
                    )}
                  </div>
                </div>
                {isNonMobile1024 && (
                  <div
                    className="p-3 black14"
                    style={{
                      width: "28.9%",
                      background: "#FFF",
                      maxHeight: "50vh",
                    }}
                  >
                    Testing Social Media
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SocialMain;
