import axios from "axios";
import React, { useState, useRef } from "react";
import { toast } from "react-toastify";

const WriteComment = ({
  user,
  post,
  token,
  setComments,
  comments,
  setTotalComments,
  setCommentShow,
  reply,
  handleCommentReply,
  name,
  loading,
  setLoading,
  Comment,
  Data,
  setEdit,
}) => {
  const [comment, setComment] = useState(
    reply ? `@${name} ` : Comment ? Comment.text : ""
  );

  const textareaRef = useRef(null);

  const handleInputChange = (e) => {
    setComment(e.target.value);

    // Adjust the height of the textarea
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto"; // Reset the height to auto to shrink if needed
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`; // Set height based on scrollHeight
    }
  };

  const handleComment = async () => {
    if (comment.trim().length > 0 && !loading) {
      setLoading(true);
      const data = {
        comment: comment,
        postId: post?._id,
        Data: Data ? Data : null,
      };

      await axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/api/${
            Comment ? "edit" : "post"
          }/comment`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          if (res.data.msg === "success") {
            if (Comment) {
              setComments((prev) =>
                prev.map((c) => {
                  if (c.uniqueId === Comment.uniqueId) {
                    return {
                      ...c,
                      text: data.comment,
                      edited: true,
                    };
                  }
                  return c;
                })
              );
              setComment("");
              setLoading(false);
              setEdit(false);
            } else {
              const newComment = {
                text: res.data.newComment.text,
                user: {
                  _id: user._id,
                  name: `${user.firstName} ${user.LastName}`,
                  profilePhoto: user.profilePhoto,
                },
                uniqueId: res.data.newComment.uniqueId,
                time: res.data.newComment.time,
                replies: [],
                likes: [],
              };
              setComments([...comments, newComment]);
              setTotalComments((prev) => prev + 1);
              setLoading(false);
              setCommentShow(true);
              setComment("");
              textareaRef.current.style.height = "44px";
            }
          } else {
            setLoading(false);
            toast.error("Error Posting Comment");
          }
        })
        .catch((err) => {
          console.log(err);
          // setLoading(false);
          toast.error("Error Putting Comment");
          setLoading(false);
        });
      setLoading(false);
    }
  };

  return (
    <div className="d-flex " style={{ gap: "0.75rem" }}>
      <img
        src={user?.profilePhoto}
        alt="profile"
        style={{
          width: "32px",
          height: "32px",
          borderRadius: "50%",
          objectFit: "contain", // Ensure the image doesn't distort
        }}
      />
      <div className="d-flex  " style={{ width: "100%", position: "relative" }}>
        <textarea
          ref={textareaRef}
          value={comment}
          className="newinputs"
          onChange={handleInputChange}
          placeholder={reply ? "reply" : "Write a comment..."}
          rows={1} // Start with 1 row
          style={{
            width: "100%",
            paddingRight: "2rem",
            //   border: "1px solid #ddd", // Border around textarea
            outline: "none", // Remove the default outline
            resize: "none", // Disable resizing
            overflow: "hidden", // Hide default scrollbars

            minHeight: "20px", // Minimum height of the textarea
            maxHeight: "300px", // Maximum height of the textarea

            boxSizing: "border-box",
          }}
        />
        {comment && (
          <div
            className="center align-items-center"
            style={{
              minWidth: "20px",
              minHeight: "20px",
              position: "absolute",
              zIndex: 1,
              right: "12px",
              bottom: "12px",
              cursor: "pointer",
            }}
            onClick={() => {
              if (reply) {
                handleCommentReply(comment);
              } else {
                handleComment();
              }
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <rect x="7" y="9" width="6" height="2" fill="#E31B54" />
              <path
                d="M15.0583 7.09129L7.92498 3.52462C3.13331 1.12462 1.16664 3.09129 3.56664 7.88296L4.29164 9.33296C4.49998 9.75796 4.49998 10.2496 4.29164 10.6746L3.56664 12.1163C1.16664 16.908 3.12498 18.8746 7.92498 16.4746L15.0583 12.908C18.2583 11.308 18.2583 8.69129 15.0583 7.09129ZM12.3666 10.6246H7.86664C7.52497 10.6246 7.24164 10.3413 7.24164 9.99962C7.24164 9.65796 7.52497 9.37462 7.86664 9.37462H12.3666C12.7083 9.37462 12.9916 9.65796 12.9916 9.99962C12.9916 10.3413 12.7083 10.6246 12.3666 10.6246Z"
                fill="#E31B54"
              />
            </svg>
          </div>
        )}
        {loading && (
          <div
            className="center align-items-center"
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              zIndex: 2,
              background: "rgba(255,255,255,0.5)",
              top: 0,
              left: 0,
            }}
          >
            <div className="line-loader"></div>
          </div>
        )}
      </div>
    </div>
  );
};

export default WriteComment;
