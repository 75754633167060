import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import Joblist from "./Joblist";
import "./main.css";
import axios from "axios";
import { toast } from "react-toastify";
import Search from "../../components/Search/Search";
import Applied from "./Applied";
const JobFlow = () => {
  const [selected, setSelected] = useState("Explore Jobs");
  const navigate = useNavigate();
  const isNonMobile = useMediaQuery("(min-width:767px)");
  const isNon1024 = useMediaQuery("(min-width:1024px)");
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [jobs, setJobs] = useState([]);
  const isNonMobile450 = useMediaQuery("(min-width:450px)");
  const [selectedJob, setSelectedJob] = useState(null);
  const [jobNum, setJobNum] = useState(0);
  const [savedIds, setSavedIds] = useState([]);
  const [applied, setApplied] = useState([]);
  const [data, setData] = useState(false);

  const ref = useRef();

  const [calculatedHeight, setCalculatedHeight] = useState("100vh");

  useEffect(() => {
    if (ref.current) {
      const headerHeight = ref.current.offsetHeight;
      // Subtract the header height and the other known height (114px)
      setCalculatedHeight(
        isNon1024
          ? `calc(100vh - ${headerHeight}px - 144px)`
          : `calc(100vh - ${headerHeight}px - 224px)`
      );
    }
  }, []);

  const FetchJobs = async (selectedItem) => {
    await axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/getJobs`,

        selectedItem,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res?.data?.jobs) {
          setJobs(res.data.jobs);
          setSavedIds(res.data.saved);
          setApplied(res.data.applied);

          if (isNon1024) {
            setSelectedJob(res.data.jobs[jobNum]);
          }
        } else {
          setJobs([]);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/login");
    } else {
      setLoading(true);

      FetchJobs();
    }
  }, []);

  useEffect(() => {
    FetchJobs();
  }, [data]);

  useEffect(() => {
    if (isNon1024) {
      setSelectedJob(jobs[jobNum]);
    } else {
      setSelectedJob(null);
    }
  }, [isNon1024, selected]);

  const handleNumChange = (value) => {
    if (selected === "Explore Jobs") {
      if (jobNum + value < 0 || jobNum + value > jobstoshow.length - 1) {
        toast.error("No more jobs to show");
      } else {
        setSelectedJob(jobstoshow[jobNum + value]);
        setJobNum((prev) => prev + value);
      }
    } else {
      if (jobNum + value < 0 || jobNum + value > savedtoshow.length - 1) {
        toast.error("No more jobs to show");
      } else {
        setSelectedJob(savedtoshow[jobNum + value]);
        setJobNum((prev) => prev + value);
      }
    }
  };

  const handleSaveJob = async () => {
    if (selectedJob) {
      await axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/saveJob`,
          { uniqueId: selectedJob.uniqueId },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((res) => {
          if (res.data.msg === "success") {
            setSavedIds(res.data.list);
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error("Failed to save job");
        });
    } else {
      toast.error("No job selected");
    }
  };

  const [jobstoshow, setJobstoshow] = useState([]);
  const [savedtoshow, setSavedtoShow] = useState([]);
  const [appliedtoshow, setAppliedToShow] = useState([]);

  useEffect(() => {
    if (isNon1024) {
      if (selected === "Explore Jobs") {
        setSelectedJob(jobstoshow[0]);
      } else if (selected === "Saved Jobs") {
        setSelectedJob(savedtoshow[0]);
      }
      setJobNum(0);
    }
  }, [selected]);

  useEffect(() => {
    if (search) {
      setJobstoshow(
        jobs.filter((job) =>
          job.title.toLowerCase().includes(search.toLowerCase())
        )
      );
      setSavedtoShow(
        savedIds.filter((job) =>
          job.title.toLowerCase().includes(search.toLowerCase())
        )
      );
      setAppliedToShow(
        applied.filter((job) =>
          job.title.toLowerCase().includes(search.toLowerCase())
        )
      );
    } else {
      setJobstoshow(jobs);
      setSavedtoShow(savedIds);
      setAppliedToShow(applied);
    }
  }, [jobs, savedIds, search]);

  return (
    <div
      className="marginsleftrightCompany"
      style={{
        paddingTop: "1.875rem",
        height:
          selected !== "Applied Jobs"
            ? isNon1024
              ? "calc(100vh - 90px)"
              : ""
            : "",
        maxHeight:
          selected !== "Applied Jobs"
            ? isNon1024
              ? "calc(100vh - 90px)"
              : ""
            : "",
        overflow: "hidden",
      }}
    >
      <div className="center" style={{ height: "100%" }}>
        <div className="headerCompany" style={{ height: "100%" }}>
          <div className="" style={{ height: "100%" }}>
            <div style={{}} ref={ref}>
              <p className="studentHeading">Job Search</p>
              <p
                className="gray60011400"
                style={{ fontSize: "14px", marginTop: "0.25rem" }}
              >
                Create events for students around the globe and attract talent.
              </p>
              <div
                className={`transition ${isNonMobile && "d-flex"} `}
                style={{
                  justifyContent: "space-between",
                  flexFlow: "wrap",
                  gap: "10px",
                  marginTop: "1.25rem",
                }}
              >
                <div className=" behindbuttons ">
                  <button
                    className={`  border-0  ${
                      selected === "Explore Jobs"
                        ? "selectedbutton rounded-2 "
                        : "secondbuttontext"
                    } ${isNonMobile ? "" : "p-1  "}`}
                    style={
                      isNonMobile
                        ? {
                            minWidth: "188px",
                          }
                        : {}
                    }
                    onClick={() => {
                      setSelected("Explore Jobs");
                    }}
                  >
                    Explore {isNonMobile450 && "Jobs"}
                  </button>
                  <button
                    className={` border-0   ${
                      selected === "Saved Jobs"
                        ? "selectedbutton rounded-2 "
                        : "secondbuttontext"
                    } ${isNonMobile ? "" : "p-1  "}`}
                    style={isNonMobile ? { minWidth: "188px" } : {}}
                    onClick={() => {
                      setSelected("Saved Jobs");
                    }}
                  >
                    Saved {isNonMobile450 && "Jobs"}
                  </button>

                  <button
                    className={`   border-0 ${
                      selected === "Applied Jobs"
                        ? "selectedbutton rounded-2 "
                        : "secondbuttontext"
                    } ${isNonMobile ? "" : "p-1  "}`}
                    style={isNonMobile ? { minWidth: "188px" } : {}}
                    onClick={() => {
                      setSelected("Applied Jobs");
                    }}
                  >
                    Applied {isNonMobile450 && "Jobs"}
                  </button>
                </div>
                <div
                  className={`d-flex  ${!isNonMobile && "mt-5"}`}
                  style={{
                    gap: "10px",
                  }}
                >
                  <Search search={search} setSearch={setSearch} />
                </div>
              </div>
            </div>
            <div
              style={{
                marginTop: "1.5rem",
                height: "auto",
              }}
            >
              {selected === "Explore Jobs" && (
                <Joblist
                  jobs={jobstoshow}
                  calculatedHeight={calculatedHeight}
                  loading={loading}
                  selectedJob={selectedJob}
                  setSelectedJob={setSelectedJob}
                  handleNumChange={handleNumChange}
                  setJobNum={setJobNum}
                  handleSaveJob={handleSaveJob}
                  savedIds={savedIds}
                  setData={setData}
                />
              )}
              {selected === "Saved Jobs" && (
                <Joblist
                  jobs={savedtoshow}
                  calculatedHeight={calculatedHeight}
                  loading={loading}
                  selectedJob={selectedJob}
                  setSelectedJob={setSelectedJob}
                  handleNumChange={handleNumChange}
                  setJobNum={setJobNum}
                  handleSaveJob={handleSaveJob}
                  savedIds={savedIds}
                  setData={setData}
                />
              )}
              {selected === "Applied Jobs" && (
                <Applied loading={loading} applied={appliedtoshow} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default JobFlow;
