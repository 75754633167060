import { useNavigate, Outlet, useLocation } from "react-router-dom";
import NewStudentHeader from "./NewHeader";
import { useMediaQuery } from "@mui/material";
import "./main.css";
import { useEffect, useState } from "react";
import FeedIcon from "../../assets/images/icons/Feed";
import ChallengeIcon from "../../assets/images/icons/ChallengeIcon";
import JobIcon from "../../assets/images/icons/JobsIcon";
import EventIcon from "../../assets/images/icons/EventIcon";
import Groups from "../../assets/images/icons/GroupIcon";
import GroupIcon from "../../assets/images/icons/GroupIcon";
import MobileNav from "./MobileNav";

const LatestStudentDashboard = ({ student, setStudent }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname.toLowerCase();
  const [selected, setSelected] = useState("Feed");
  const isNon = useMediaQuery("(min-width: 769px)");

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/login");
    }

    if (path === "/student/dashboard") {
      setSelected("Feed");
    } else if (path.includes("/student/dashboard/challenges")) {
      setSelected("Challenges");
    } else if (path.includes("/student/dashboard/jobs")) {
      setSelected("Jobs");
    } else if (path.includes("/student/dashboard/events")) {
      setSelected("Events");
    } else if (path.includes("/student/dashboard/groups")) {
      setSelected("Groups");
    }
  }, [path]);

  const buttons = [
    {
      name: "Feed",
      icon: <FeedIcon color={selected === "Feed" ? "#E31b54" : "#344054"} />,
    },
    {
      name: "Challenges",
      icon: (
        <ChallengeIcon
          color={selected === "Challenges" ? "#E31b54" : "#344054"}
        />
      ),
    },
    {
      name: "Jobs",
      icon: <JobIcon color={selected === "Jobs" ? "#E31b54" : "#344054"} />,
    },
    {
      name: "Events",
      icon: <EventIcon color={selected === "Events" ? "#E31b54" : "#344054"} />,
    },
    {
      name: "Groups",
      icon: <GroupIcon color={selected === "Groups" ? "#E31b54" : "#344054"} />,
    },
  ];

  return (
    <div
      style={{
        width: "100%",
        minHeight: "100vh",
        background: "#F2F3F7",
        scrollBehavior: "smooth",
      }}
    >
      <NewStudentHeader
        student={student}
        setStudent={setStudent}
        setSelected={setSelected}
        selected={selected}
        navigate={navigate}
        isNon={isNon}
        buttons={buttons}
      />
      <div
        style={{
          paddingTop: "60px",
          paddingBottom: isNon ? "60px" : "120px",
        }}
      >
        <Outlet />
      </div>
      {!isNon && (
        <div style={{ position: "fixed", bottom: "0", left: "0" }}>
          <MobileNav
            buttons={buttons}
            setSelected={setSelected}
            selected={selected}
            navigate={navigate}
          />
        </div>
      )}
    </div>
  );
};

export default LatestStudentDashboard;
