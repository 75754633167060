import { useState } from "react";
import CompanyImage from "../../components/Company/CompanyImage";
import EventTypes from "../NewPages/Events/EventsTypes";
import GetDayOfMonth from "./getDayOfMonth";
import GetDayOfWeek from "./GetDayOfWeek";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import EventDetails from "./EventDetails";
import sample from "../../assets/images/eventsample.png";
import { useMediaQuery } from "@mui/material";

const Event = ({ event, user, setEvents }) => {
  const [showDetails, setShowDetails] = useState(false);
  const isNon = useMediaQuery("(min-width:767px)");
  const isNon500 = useMediaQuery("(min-width:500px)");
  function isWithinNextThreeDays(eventDatetime) {
    const eventDate = new Date(eventDatetime);
    const now = new Date();

    // Get the difference in time
    const timeDiff = eventDate.getTime() - now.getTime();

    // Convert time difference from milliseconds to days
    const dayDiff = timeDiff / (1000 * 3600 * 24);

    return dayDiff >= 0 && dayDiff <= 3;
  }
  return (
    <div className="upcoming-inner" style={{ cursor: "auto" }} key={event?._id}>
      <div
        className="d-flex"
        style={{ gap: "1.5rem", flexDirection: isNon500 ? "row" : "column" }}
      >
        <div
          className={`${isNon ? "" : "center flex-column align-items-center"}`}
        >
          <p
            className="upcomingDay"
            style={{
              color: isWithinNextThreeDays(event?.datetime)
                ? "#F97066"
                : "inherit",
            }}
          >
            <GetDayOfWeek datetime={event?.datetime} />
          </p>
          <p
            className="upcomingDate"
            style={{
              color: isWithinNextThreeDays(event?.datetime)
                ? "#F97066"
                : "inherit",
            }}
          >
            <GetDayOfMonth datetime={event?.datetime} />
          </p>
        </div>
        <div className="center align-items-center">
          <div
            style={{
              height: isNon500 ? "100%" : "1px",
              width: isNon500 ? "1px" : "100%",
              background: "#D0D5DD",
            }}
          />
        </div>
        <div
          className="d-flex flex-column"
          style={{ gap: "0.5rem", width: "100%" }}
        >
          <div
            className="d-flex"
            style={{ gap: "1rem", flexDirection: isNon500 ? "row" : "column" }}
          >
            {event?.company && (
              <CompanyImage src={JSON.parse(event.company).img} />
            )}
            <p
              style={{
                fontWeight: 400,
                width: "100%",
                fontSize: "16px",
                lineHeight: "24px",
                color: "#0C111D",
              }}
            >
              {event?.title}
            </p>
          </div>
          <div className="space flex-wrap" style={{ gap: "1rem" }}>
            <div className="d-flex flex-wrap" style={{ gap: "1rem" }}>
              <div className="d-flex">
                <EventTypes
                  noback={true}
                  type={
                    event?.type === "varyance Livestream"
                      ? "Live Stream"
                      : event?.type === "Job Schedule"
                      ? "Job Closings"
                      : event.type
                  }
                />
              </div>
              <p
                className="gray60011400 d-flex align-items-center gap-2"
                style={{
                  fontWeight: 500,
                  fontSize: "14px",
                  lineHeight: "20px",
                }}
              >
                Company:{" "}
                <span className="gray80014400">
                  {JSON.parse(event?.company)?.title}
                </span>
              </p>
            </div>
            <div className="d-flex flex-column justify-content-end">
              <div
                className="d-flex pink14 hovercolorback align-items-center"
                style={{ gap: "0.5rem", cursor: "pointer", minWidth: "107px" }}
                onClick={() => {
                  setShowDetails(!showDetails);
                }}
              >
                See Details
                <KeyboardArrowDownIcon
                  style={{
                    height: "20px",
                    width: "20px",
                    transform: showDetails ? "rotate(180deg)" : "",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          marginTop: showDetails ? "2.5rem" : "0rem",
          height: showDetails ? "" : "1px",
          opacity: showDetails ? 1 : 0,
          transition: "all 0.2s ease-in-out",
        }}
      >
        {showDetails && (
          <EventDetails
            event={event}
            sample={sample}
            user={user}
            setEvents={setEvents}
          />
        )}
      </div>
    </div>
  );
};

export default Event;
