import { useEffect, useRef, useState } from "react";
import axios from "axios";
import "./post.css";
import PostMedia from "./MediaGrid";
import Interaction from "./Interaction";
import WriteComment from "./WriteComment";
import PostComments from "./AllComments";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { toast } from "react-toastify";
import deleteicon from "../../assets/images/warning.png";
import CloseIcon from "@mui/icons-material/Close";

const PostContainer = ({
  post,
  student,
  setSelectedPost,
  selected,
  getTimeElapsed,
  noMedia = false,
  setPosts,
  setEdit,
  isNon384,
}) => {
  const [user, setUser] = useState({});

  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [likes, setLikes] = useState([]);
  const [commentShow, setCommentShow] = useState(noMedia ? true : false);
  const [totalComments, setTotalComments] = useState(0);
  const [commentOption, setCommentOption] = useState("Most recent");
  const [page, setPage] = useState(1);
  const [popup, setPopup] = useState(false);
  const popupref = useRef(null);
  const [loadingComments, setLoadingComments] = useState(false);
  const [loadingapi, setLoadingApi] = useState(false);

  const reportOptions = [
    "Nudity",
    "Violence",
    "Harassment",
    "Suicide or self-injury",
    "False information",
    "Spam",
    "Unauthorized sales",
    "Hate speech",
    "Terrorism",
    "Something Else",
  ];

  const [selectedReportOption, setSelectedOption] = useState("Nudity");

  const [reportPopup, setReportPopup] = useState(false);
  const [Data, setData] = useState(null);
  const [text, setText] = useState("");
  const handleReportComment = async (data) => {
    setData(data);
    setReportPopup(true);
  };

  const LoadMoreComments = async () => {
    setLoadingComments(true);
    await axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/get/more-comments`,
        { postId: post._id, page: page },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      )
      .then((res) => {
        if (res.data?.msg === "success") {
          setComments([...comments, ...res.data.comments]);
          setPage((prev) => prev + 1);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
    setLoadingComments(false);
  };

  const SetSelectedPost = () => {
    setSelectedPost({ post: post, user: user });
  };

  useEffect(() => {
    if (post) {
      setComments(post?.comments);
      setLikes(post?.likes);
      setTotalComments(post.totalComments ? post?.totalComments : 0);
      setCommentOption("Most recent");
      setCommentShow(false);
    }
  }, [post]);

  useEffect(() => {
    if (commentOption === "All Comments") {
      window.alert("All Comments");
    }
  }, [commentOption]);

  useEffect(() => {
    const getuser = async () => {
      await axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/get/user/details/uniqueId`,
          { userId: post?.owner },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((res) => {
          if (res.data.message === "Found") {
            setUser(res.data.user);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };
    if (post) {
      getuser();
    }
  }, [post]);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1440 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 1440, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });

    function handleClickOutside(event) {
      if (popupref.current && !popupref.current.contains(event.target)) {
        setPopup(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSavePost = async (test) => {
    if (!loadingapi) {
      setLoadingApi(true);
      await axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/api/post/${
            test ? "save" : "hide"
          }`,
          { postId: post._id },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((res) => {
          if (res.data.msg === "success") {
            toast.success(test ? "Post saved" : "Post hidden");
            if (!test) {
              setPosts((prev) => {
                return prev.filter((p) => p._id !== post._id);
              });
              setSelectedPost(null);
            }
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
      setLoadingApi(false);
      setPopup(false);
    }
  };

  const [reportloading, setReportLoading] = useState(false);

  const handleReportPost = async () => {
    if (!reportloading) {
      setReportLoading(true);
      await axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/api/post/report`,
          {
            postId: post._id,
            reason:
              selectedReportOption === "Something Else"
                ? text
                  ? text
                  : selectedReportOption
                : selectedReportOption,
            Data: Data,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((res) => {
          if (res.data.msg === "success") {
            toast.success(`${Data ? "Comment" : "Post"} reported`);
          } else if (res.data.msg === "Already") {
            toast.warning("Already Reported");
          }
        })
        .catch((err) => {
          console.log(err);
        });
      setReportPopup(false);
      setData(null);
      setSelectedOption(reportOptions[0]);
      setReportLoading(false);
    }
  };

  const handleDeletePost = async () => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/api/post/delete`,
        {
          postId: post._id,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.msg === "success") {
          toast.success("Post deleted");
          setPosts((prev) => {
            return prev.filter((p) => p._id !== post._id);
          });
          setSelectedPost(null);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      {reportPopup && (
        <div
          className="center zindex1000"
          style={{
            width: "100%",
            height: "100%",

            position: "fixed",
            alignItems: "center",
            top: 0,
            left: 0,
            right: 0,
            zIndex: 11,
            backgroundColor: "rgba(45, 50, 130, 0.30)",
            backdropFilter: "blur(3px)",
            padding: "1rem",
            zIndex: 9999,
          }}
          onClick={() => {
            setReportPopup(false);
            setData(null);
          }}
        >
          <div
            className=" shadowa newPopUpContainer"
            ref={popupref}
            style={{ width: "600px", overflow: "auto" }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div className="space " style={{ gap: "1rem" }}>
              <div
                className="d-flex flex-wrap align-items-start "
                style={{ gap: "1.5rem" }}
              >
                <div className="circle-background">
                  <img
                    src={deleteicon}
                    alt="delete"
                    height="48px"
                    width="48px"
                  />
                </div>
                <div>
                  <p className="gray90018500 mt-2">Report</p>
                </div>
              </div>
              <CloseIcon
                className="hoverdarkblacksvg"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setReportPopup(false);
                  setData(null);
                }}
              />
            </div>
            <div
              className="d-flex flex-column"
              style={{
                marginTop: "1.75rem",
                marginLeft: isNon384 ? "4rem" : "0rem",
                gap: "1rem",
              }}
            >
              {reportOptions.map((option, index) => (
                <div
                  key={index}
                  className="d-flex"
                  style={{ gap: "0.825rem", cursor: "pointer" }}
                  onClick={() => {
                    setSelectedOption(option);
                  }}
                >
                  {selectedReportOption === option ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M10 18.4166C5.35164 18.4166 1.58337 14.6483 1.58337 9.99992C1.58337 5.35152 5.35164 1.58325 10 1.58325C14.6484 1.58325 18.4167 5.35152 18.4167 9.99992C18.4167 14.6483 14.6484 18.4166 10 18.4166Z"
                        stroke="#E31B54"
                        strokeWidth="1.5"
                      />
                      <path
                        d="M5 10C5 12.7614 7.23858 15 10 15C12.7614 15 15 12.7614 15 10C15 7.23858 12.7614 5 10 5C7.23858 5 5 7.23858 5 10Z"
                        fill="#E31B54"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <g clipPath="url(#clip0_1063_20955)">
                        <path
                          d="M2.16663 10.0001C2.16663 5.67622 5.6761 2.16675 9.99996 2.16675C14.3238 2.16675 17.8333 5.67622 17.8333 10.0001C17.8333 14.3239 14.3238 17.8334 9.99996 17.8334C5.6761 17.8334 2.16663 14.3239 2.16663 10.0001ZM2.83329 10.0001C2.83329 13.9596 6.04048 17.1667 9.99996 17.1667C13.9594 17.1667 17.1666 13.9596 17.1666 10.0001C17.1666 6.04061 13.9594 2.83341 9.99996 2.83341C6.04048 2.83341 2.83329 6.04061 2.83329 10.0001Z"
                          fill="#667085"
                          stroke="#667085"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1063_20955">
                          <rect width="20" height="20" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  )}
                  <p className="gray60011400" style={{ fontSize: "14px" }}>
                    {option}
                  </p>
                </div>
              ))}
              {selectedReportOption === "Something Else" && (
                <textarea
                  className="newinputs col-md-12 col-12"
                  value={text}
                  placeholder="Type your reason here"
                  onChange={(e) => setText(e.target.value)}
                />
              )}
            </div>
            <div
              className="d-flex justify-content-end"
              style={{ marginTop: "2rem", gap: "0.75rem" }}
            >
              <button
                className="continuebutton"
                onClick={() => {
                  handleReportPost();
                  setPopup(false);
                }}
                disabled={reportloading}
              >
                Submit Report
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="postcontainer-outer shadowa ">
        <div className="postcontainer-inner">
          {user && (
            <div
              className={`${noMedia ? "d-flex" : "space"}`}
              style={{ gap: "1rem" }}
            >
              <div className="d-flex" style={{ gap: "1rem" }}>
                {user?.profilePhoto && (
                  <div className="profilephotocontainer justify-content-center align-items-center ">
                    <img
                      src={user?.profilePhoto}
                      alt="profile"
                      className=""
                      height={"40px"}
                      width={"40px"}
                    />
                  </div>
                )}
                <div>
                  <p className="postUserName text-start">
                    {user?.firstName} {user?.LastName}
                  </p>
                  <p className="gray50012500" style={{ fontWeight: 400 }}>
                    {getTimeElapsed(post?.createdAt)}
                  </p>{" "}
                  {post?.edited ? <p className="gray50012500">Edited</p> : null}
                </div>
              </div>
              <div>
                <MoreVertIcon
                  className="hoverred"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setPopup(!popup);
                  }}
                />

                {popup && (
                  <div style={{ position: "relative" }}>
                    <div
                      className="shadowa"
                      style={{
                        position: "absolute",
                        zIndex: 5,
                        right: "0",
                        width: "180px",
                      }}
                      ref={popupref}
                    >
                      {post.owner === student._id ? (
                        <>
                          <button
                            className="dropdowntopbutton"
                            onClick={() => {
                              setEdit(post);
                              setPopup(false);
                              setSelectedPost(null);
                            }}
                          >
                            Edit Post
                          </button>
                          <button
                            className="dropdownbottombutton"
                            onClick={() => {
                              handleDeletePost();
                              setPopup(false);
                            }}
                          >
                            Delete Post
                          </button>
                        </>
                      ) : (
                        <>
                          {" "}
                          <button
                            className="dropdowntopbutton"
                            onClick={() => {
                              handleSavePost({ test: true });
                            }}
                          >
                            Save Post
                          </button>
                          <button className="dropdownmiddlebutton">
                            Copy Link
                          </button>
                          <button
                            className="dropdownmiddlebutton"
                            onClick={() => {
                              handleSavePost();
                            }}
                          >
                            Hide Post
                          </button>
                          <button
                            className="dropdownbottombutton"
                            onClick={() => {
                              setReportPopup(true);
                              setPopup(false);
                              setData(null);
                            }}
                          >
                            Report
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          {post?.content && (
            <p style={{ marginTop: "0.75rem" }} className="posttext">
              {post?.content}
            </p>
          )}
          {!noMedia && post?.media && post?.media?.length > 0 && (
            <div style={{ marginTop: "1rem", marginBottom: "1rem" }}>
              <PostMedia
                media={post?.media}
                SetSelectedPost={SetSelectedPost}
              />
            </div>
          )}
          <div style={{ marginTop: "1rem" }}>
            <Interaction
              post={post}
              token={localStorage.getItem("token")}
              id={student?._id}
              comments={comments}
              likes={likes}
              setLikes={setLikes}
              totalComments={totalComments}
              setCommentShow={setCommentShow}
            />
          </div>
          <div style={{ marginTop: "1.25rem" }}>
            <WriteComment
              post={post}
              user={student}
              token={localStorage.getItem("token")}
              comments={comments}
              setComments={setComments}
              setTotalComments={setTotalComments}
              setCommentShow={setCommentShow}
              setLoading={setLoading}
              loading={loading}
            />
          </div>
          {comments.length > 0 && (
            <div
              className={`allComments ${
                commentShow || noMedia ? "showcomment" : "hidecomment"
              }`}
              style={{
                margin: commentShow ? "1.25rem 0rem" : "0rem",
                overflow: "hidden", // Always hidden to allow the maxHeight transition
              }}
            >
              <PostComments
                comments={comments}
                post={post}
                noMedia={noMedia}
                student={student}
                commentOption={commentOption}
                setCommentOption={setCommentOption}
                token={localStorage.getItem("token")}
                getTimeElapsed={getTimeElapsed}
                setComments={setComments}
                setTotalComments={setTotalComments}
                handleReportComment={handleReportComment}
              />
              {comments.length !== totalComments && (
                <div style={{ marginTop: "1.25rem" }}>
                  {loadingComments ? (
                    <div
                      className="center align-items-center"
                      style={{
                        width: "100%",
                        height: "100%",
                        zIndex: 2,
                        background: "rgba(255,255,255,0.5)",
                      }}
                    >
                      <div className="line-loader"></div>
                    </div>
                  ) : (
                    <p
                      className="gray70014400 hoverred"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        LoadMoreComments();
                      }}
                    >
                      Load More comments
                    </p>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default PostContainer;
