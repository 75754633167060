import { useEffect, useState } from "react";
import logo from "../../assets/images/logo.png";
import Popup from "../StudentDashboard/Challenges/popup";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const NewStudentHeader = ({
  student,
  setStudent,
  setSelected,
  selected,
  navigate,
  isNon,
  buttons,
}) => {
  const [popup, setPopup] = useState(false);

  useEffect(() => {
    if (student && !student.levelofstudy) {
      setPopup(true);
    }
  }, [student]);

  return (
    <>
      <div style={{ position: "relative", zIndex: 100 }}>
        {popup && (
          <Popup user={student} setPopup={setPopup} setStudent={setStudent} />
        )}
      </div>
      <div className="LatestStudentHeader shadowa">
        <div
          className="marginsleftrightCompany"
          style={{ padding: "10px 0px" }}
        >
          <div className="center">
            <div className="headerCompany space align-items-center">
              <img
                src={logo}
                alt="logo"
                className="shadowa "
                height={"36px"}
                width={"36px"}
                style={{ borderRadius: "4px", background: "transparent" }}
              />
              {isNon && (
                <div
                  className="d-flex align-items-center"
                  style={{ gap: "1rem" }}
                >
                  {buttons.map((btn, index) => (
                    <button
                      key={index}
                      className="student-header-button"
                      style={{ color: selected === btn.name ? "#E31b54" : "" }}
                      onClick={() => {
                        setSelected(btn.name);
                        if (btn.name === "Feed") {
                          navigate("/student/dashboard");
                        } else {
                          navigate(`/student/dashboard/${btn.name}`);
                        }
                      }}
                    >
                      {btn.icon}
                      {btn.name}
                    </button>
                  ))}
                </div>
              )}
              <div
                className="d-flex align-items-center"
                style={{ gap: "0.75rem" }}
              >
                <button className="student-header-button-support">
                  <InfoOutlinedIcon style={{ height: "20px", width: "20px" }} />
                  Support
                </button>
                {student?.profilePhoto && (
                  <img
                    src={student.profilePhoto}
                    alt="profilePhoto"
                    className="student-profile-pic shadowa"
                    style={{ height: "36px", width: "36px" }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default NewStudentHeader;
