import ChallengeType from "../../MainDashBoard/type";

const Third = () => {
  const types = ["Fast-Track", "Virtual Experience", "Insights"];
  return (
    <div style={{ padding: "8.125rem 0rem 3.75rem 0" }}>
      <div className="marginsleftrightCompany">
        <div className="center">
          <div className="headerCompany">
            <div className="center align-items-center flex-column">
              <p
                className="text-center itsearly "
                style={{ maxWidth: "500px", width: "100%" }}
              >
                Track engagement level across different{" "}
                <span className="pinkspan">touchpoints</span>
              </p>
              <p
                className="text-center gray95020400"
                style={{
                  maxWidth: "520px",
                  margin: "1rem 0rem",
                  fontFamily: "Poppins",
                }}
              >
                Engagement analytics helps you make data-driven decisions in
                hiring, giving you a clear picture of how candidates interact
                with your employer brand across touchpoints.
              </p>
              <div className="d-flex flex-wrap" style={{ gap: "1rem" }}>
                {types.map((ty, index) => (
                  <div key={index}>
                    <ChallengeType type={ty} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Third;
