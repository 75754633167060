import { motion, useTransform, useScroll } from "framer-motion";
import React, { useRef, useState, useCallback, useEffect } from "react";
import emailjs from "@emailjs/browser";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { toast } from "react-toastify";
// Wrap the component with React.memo
const HorizontalScrollCarousel = React.memo(({ isNonMobile1000, navigate }) => {
  const targetRef = useRef();
  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ["start end", "end start"], // Adjust the offsets to trigger the animation at the right time
  });
  const width = useTransform(scrollYProgress, [0, 0.4], ["82%", "100vw"]);
  const height = useTransform(
    scrollYProgress,
    [0, 0.3],
    [isNonMobile1000 ? "300px" : "600px", isNonMobile1000 ? "458px" : "600px"]
  );
  const borderRadius = useTransform(
    scrollYProgress,
    [0, 0.3],
    [isNonMobile1000 ? "10px" : "20px", "0px"]
  );
  const [loading, setLoading] = useState(false);
  const iconRef = useRef();
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });

    function handleClickOutside(event) {
      if (iconRef.current && !iconRef.current.contains(event.target)) {
        setShowDropDown(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [name, setName] = useState("");
  const [workMail, setWorkMail] = useState("");
  const [companySize, setCompanySize] = useState("");
  const [check, setCheck] = useState(false);
  const [showDropDown, setShowDropDown] = useState(false);
  const sendEmail = useCallback(() => {
    setLoading(true);
    const payload = {
      from_name: name,
      name: name,
      from_email: workMail,
      company_size: companySize,
    };

    emailjs
      .send("service_08a640e", "template_65hjrwp", payload, "FCKBh2iU92kGQhhAA")
      .then(
        (result) => {
          console.log(result.text);
          setWorkMail("");
          setCheck(false);
          setCompanySize("");
          setName("");
          toast.success("Email sent successfully");
          setLoading(false);
        },
        (error) => {
          console.log(error.text);
          toast.error("Error Sending Email");
          setLoading(false);
        }
      );
  }, [name, workMail, companySize]);

  const options = ["1-10", "11-50", "51-500", "501-5000", "5000+"];

  return (
    <>
      <section
        ref={targetRef}
        style={{ height: "fit-content", position: "relative" }}
        className="center align-items-center"
      >
        <div style={{ position: "relative" }}>
          <motion.div
            className="d-flex contactusBackground"
            style={{
              width,
              height,
              borderRadius,
              paddingTop: isNonMobile1000 ? "3rem" : "",
              paddingBottom: isNonMobile1000 ? "3rem" : "",
              // minWidth: "fit-content",
              // overflow: "hidden",
            }}
          >
            <div className="marginsleftrightCompany" style={{ width: "100%" }}>
              <div className="center">
                <div className="d-flex headerCompany">
                  <div
                    className={`d-flex  space gap-5 w-100 ${
                      isNonMobile1000 ? "pt-5" : ""
                    }`}
                    style={{
                      padding: "1.25px",
                      flexDirection: isNonMobile1000 ? "row" : "column",
                    }}
                  >
                    <div>
                      <h2 className="filmo48400">Let’s get started</h2>
                      <p className="white20500" style={{ marginTop: "1.5rem" }}>
                        Discover early talent with varyance.
                      </p>
                      <a
                        href="mailto:hello@varyance.io"
                        style={{ textDecoration: "none" }}
                      >
                        <div
                          className="d-flex "
                          style={{
                            gap: "0.75rem",
                            marginTop: "2rem",
                            cursor: "pointer",
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M17 3.5H7C4 3.5 2 5 2 8.5V15.5C2 19 4 20.5 7 20.5H17C20 20.5 22 19 22 15.5V8.5C22 5 20 3.5 17 3.5ZM17.47 9.59L14.34 12.09C13.68 12.62 12.84 12.88 12 12.88C11.16 12.88 10.31 12.62 9.66 12.09L6.53 9.59C6.21 9.33 6.16 8.85 6.41 8.53C6.67 8.21 7.14 8.15 7.46 8.41L10.59 10.91C11.35 11.52 12.64 11.52 13.4 10.91L16.53 8.41C16.85 8.15 17.33 8.2 17.58 8.53C17.84 8.85 17.79 9.33 17.47 9.59Z"
                              fill="white"
                            />
                          </svg>
                          <p className="white20500">hello@varyance.io</p>
                        </div>
                      </a>
                    </div>
                    <div
                      style={{
                        width: isNonMobile1000 ? "40%" : "100%",
                        position: "relative",
                      }}
                    >
                      <input
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Name"
                        className="newinputs col-md-12 col-12"
                      />
                      <input
                        value={workMail}
                        onChange={(e) => setWorkMail(e.target.value)}
                        style={{ marginTop: "1.5rem" }}
                        placeholder="Work Email"
                        className="newinputs col-md-12 col-12"
                      />

                      <div
                        className="newinputs col-md-12 col-12 space"
                        style={{ marginTop: "1.5rem", cursor: "pointer" }}
                        onClick={() => setShowDropDown(!showDropDown)}
                        placeholder="Company Size"
                      >
                        {companySize ? companySize : "Company Size"}
                        <ArrowDropDownIcon
                          style={{ width: "20px", height: "20px" }}
                        />
                      </div>
                      {showDropDown && (
                        <div
                          className="rounded-2 shadowa"
                          style={{
                            position: "absolute",
                            width: "100%",
                            background: "#FFF",
                          }}
                          ref={iconRef}
                        >
                          {options.map((opt, index) => (
                            <div
                              key={index}
                              className="formtext "
                              style={{
                                borderBottom:
                                  index !== options.length - 1 &&
                                  "1px solid #E0E0E0",
                                cursor: "pointer",
                                padding: "10px 16px",
                              }}
                              onClick={() => {
                                setCompanySize(opt);
                                setShowDropDown(false);
                              }}
                            >
                              {opt}
                            </div>
                          ))}
                        </div>
                      )}
                      {/* <select
                        className="newinputs col-md-12 col-12 dropdowniconnn"
                        style={{ marginTop: "1.5rem" }}
                        value={companySize}
                        onChange={(e) => setCompanySize(e.target.value)}
                      >
                        <option value="" disabled>
                          Company Size
                        </option>
                        <option value="1-10" className="formtext">
                          1-10 employees
                        </option>
                        <option value="11-50" className="formtext">
                          11-50 employees
                        </option>
                        <option value="51-500" className="formtext">
                          51-500 employees
                        </option>
                        <option value="501-5000" className="formtext">
                          501 - 5000 employees
                        </option>
                        <option value="5000+" className="formtext">
                          5000+ employees
                        </option>
                      </select> */}
                      <button
                        className={`col-md-12 col-12 backbutton pink16 ${
                          name && workMail && companySize
                            ? "hovertest"
                            : "disabled"
                        }`}
                        style={{ marginTop: "2rem" }}
                        disabled={!name || !workMail || !companySize || loading}
                        onClick={sendEmail}
                      >
                        Send Email
                      </button>
                      {/* <div
                        className="d-flex align-items-center "
                        style={{ marginTop: "1.5rem", gap: "0.75rem" }}
                      >
                        <div
                          className=""
                          style={{
                            width: "20px",
                            height: "20px",
                            border: "1px solid #FFF",
                            cursor: "pointer",
                            borderRadius: "6px",
                            background: check ? "white" : "transparent",
                          }}
                          onClick={() => setCheck(!check)}
                        />
                        <p className="white16" style={{ fontWeight: 400 }}>
                          Learn how varyance can help your business
                        </p>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      </section>
    </>
  );
});

const GetStarted = ({ isNonMobile1000, navigate }) => {
  return (
    <div
      className="d-flex"
      style={{
        justifyContent: "center",
      }}
    >
      <div className="headerCompany">
        <HorizontalScrollCarousel
          isNonMobile1000={isNonMobile1000}
          navigate={navigate}
        />
      </div>
    </div>
  );
};

export default GetStarted;
