import React from "react";
import logo from "../../assets/images/logo.png";
import megazine from "../../assets/images/megazine.png";
import { useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
const HomeFooter = () => {
  const isNonMobile = useMediaQuery("(min-width:768px)");
  const isNonMobile1025 = useMediaQuery("(min-width:1025px)");
  const isNonMobile450 = useMediaQuery("(min-width:450px)");
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname.toLowerCase();

  const handleClick = () => {
    if (path === "/") {
      navigate("/");
    } else {
      navigate("/company");
    }
    window.scrollTo(0, 0);
  };
  return (
    <div className="marginfromlinetop">
      <div
        className={`${isNonMobile1025 && "d-flex"}`}
        style={{ justifyContent: "space-between" }}
      >
        <div className="footer1st">
          <img
            src={logo}
            alt="logo"
            className="rounded-2 headerlogohome"
            style={{ cursor: "pointer" }}
            onClick={() => handleClick()}
          />
          <div>
            <p
              className="varyance"
              style={{ cursor: "pointer" }}
              onClick={() => handleClick()}
            >
              varyance.io
            </p>
            <p className="Accelerate">Fast-track your career start</p>
          </div>
        </div>
        <div
          className={`footersecond ${isNonMobile450 && "d-flex"}`}
          style={{ marginTop: isNonMobile450 ? "2rem" : "" }}
        >
          <div
            className="footerseconddiv"
            style={{ marginTop: isNonMobile450 ? "" : "2rem" }}
          >
            <p className="secondfirsttext">Student</p>
            <div
              className="d-flex"
              style={{ flexDirection: "column", gap: "1.063rem" }}
            >
              <p
                className="secondsametexts  p-0 text-start"
                style={{ cursor: "pointer" }}
                onClick={() => handleClick()}
              >
                Home
              </p>
              <p
                className="secondsametexts  p-0 text-start"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  if (path === "/") {
                    navigate("/register");
                    window.scrollTo(0, 0);
                  } else {
                    navigate("/company/register");
                    window.scrollTo(0, 0);
                  }
                }}
              >
                Registration
              </p>
              <p
                className="d-flex secondsametexts  p-0 text-start"
                style={{
                  gap: "12px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() => {
                  window.location.href = "https://varyance.io/magazine";
                }}
              >
                <img
                  src={megazine}
                  alt="megazine"
                  className="megazineimg rounded-1"
                />{" "}
                Magazine
              </p>
            </div>
          </div>
          <div
            className="footerseconddiv"
            style={{ marginTop: isNonMobile450 ? "" : "2rem" }}
          >
            <p className="secondfirsttext">Employer HUB</p>
            <div
              className="d-flex"
              style={{ flexDirection: "column", gap: "1.063rem" }}
            >
              <p
                className="secondsametexts  p-0 text-start"
                onClick={() => {
                  navigate("/company");
                  window.scrollTo(0, 0);
                }}
              >
                Solutions
              </p>
              <p
                className="secondsametexts  p-0 text-start"
                onClick={() => {
                  navigate("/company/register");
                  window.scrollTo(0, 0);
                }}
              >
                Onboarding
              </p>
              <p
                className="d-flex secondsametexts  p-0 text-start"
                onClick={() => {
                  navigate("/company/login");
                  window.scrollTo(0, 0);
                }}
              >
                Login
              </p>
            </div>
          </div>
          <div
            className="footerseconddiv"
            style={{ marginTop: isNonMobile450 ? "" : "2rem" }}
          >
            <p className="secondfirsttext">Career Centers</p>
            <div
              className="d-flex"
              style={{ flexDirection: "column", gap: "1.063rem" }}
            >
              <p
                className="secondsametexts  p-0 text-start"
                onClick={() => {
                  navigate("/career-centers");
                  window.scrollTo(0, 0);
                }}
              >
                Overview
              </p>
              <p
                className="secondsametexts  p-0 text-start"
                onClick={() => {
                  navigate("/career-centers/marketing-toolkit/");
                  window.scrollTo(0, 0);
                }}
              >
                Marketing Toolkit
              </p>
              <p
                className="d-flex secondsametexts  p-0 text-start"
                onClick={() => {
                  navigate("/career-center/contact");
                  window.scrollTo(0, 0);
                }}
              >
                Book a Demo
              </p>
            </div>
          </div>
        </div>
      </div>

      <hr className="marginfromlinetop" />

      <div
        className={` d-flex mb-5`}
        style={{
          marginTop: "2.5rem",
          justifyContent: isNonMobile ? "space-between" : "center",
          flexDirection: isNonMobile ? "row" : "column",
        }}
      >
        <p className="ltd">© 2024 varyance LTD.</p>
        <div
          className="d-flex"
          style={{ gap: "2rem", marginTop: isNonMobile ? "" : "1rem" }}
        >
          {/* <a
            className="ltd p-0"
            style={{ cursor: "pointer", textDecoration: "none" }}
            href="mailto:support@varyance.io"
          >
            Email Support
          </a> */}
          <p
            className="ltd p-0"
            style={{ cursor: "pointer", textDecoration: "none" }}
            onClick={() => window.open("/terms", "_blank")}
          >
            Terms of Use
          </p>
          <p
            className="ltd p-0"
            style={{ cursor: "pointer", textDecoration: "none" }}
            onClick={() => window.open("/privacy", "_blank")}
          >
            Privacy Policy
          </p>
          <p
            className="ltd p-0"
            style={{ cursor: "pointer", textDecoration: "none" }}
            onClick={() => window.open("/cookie", "_blank")}
          >
            Cookies
          </p>
        </div>
      </div>
    </div>
  );
};

export default HomeFooter;
