const Fifthnew = () => {
  return (
    <div style={{ padding: "8.125rem 0rem 3.75rem 0" }}>
      <div className="marginsleftrightCompany">
        <div className="center">
          <div className="headerCompany">
            <div className="center align-items-center flex-column">
              <p
                className="text-center itsearly "
                style={{ maxWidth: "400px", width: "100%" }}
              >
                Engage talent with <br />
                <span className="pinkspan">3 different</span> event types.
              </p>
              <p
                className="text-center gray95020400"
                style={{
                  maxWidth: "520px",
                  margin: "1rem 0rem",
                  fontFamily: "Poppins",
                }}
              >
                With{" "}
                <span
                  className="gray95020400"
                  style={{ fontFamily: "Poppins", fontWeight: 500 }}
                >
                  Events
                </span>{" "}
                you attract and engage more early talent without visiting more
                campuses.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Fifthnew;
