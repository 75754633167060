import React, {
  createContext,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from "react";
import confetti from "canvas-confetti";
import { Button } from "react-bootstrap"; // Import Bootstrap Button

// Create context to expose the Confetti API
const ConfettiContext = createContext({});

// Confetti component
const Confetti = forwardRef((props, ref) => {
  const {
    options = {
      particleCount: 150, // Adjust the number of particles
      spread: 60, // Spread angle of the confetti
      gravity: 0.5, // Slows down the confetti fall
      startVelocity: 30, // Adjust the velocity of the confetti particles
      ticks: 300, // Controls how long the confetti stays on screen
    },
    globalOptions = { resize: true, useWorker: true },
    manualstart = false,
    children,
    ...rest
  } = props;

  const instanceRef = useRef(null);

  const canvasRef = useCallback(
    (node) => {
      if (node !== null) {
        if (instanceRef.current) return;
        instanceRef.current = confetti.create(node, {
          ...globalOptions,
          resize: true,
        });
      } else {
        if (instanceRef.current) {
          instanceRef.current.reset();
          instanceRef.current = null;
        }
      }
    },
    [globalOptions]
  );

  const fire = useCallback(
    (opts = {}) => instanceRef.current?.({ ...options, ...opts }),
    [options]
  );

  const api = useMemo(
    () => ({
      fire,
    }),
    [fire]
  );

  useImperativeHandle(ref, () => api, [api]);

  useEffect(() => {
    if (!manualstart) {
      fire();
    }
  }, [manualstart, fire]);

  return (
    <ConfettiContext.Provider value={api}>
      <canvas
        ref={canvasRef}
        {...rest}
        style={{ width: "100%", height: "100%" }}
      />
      {children}
    </ConfettiContext.Provider>
  );
});

// Button component that triggers confetti
function ConfettiButton({ options, children, ...props }) {
  const handleClick = (event) => {
    const rect = event.currentTarget.getBoundingClientRect();
    const x = rect.left + rect.width / 2;
    const y = rect.top + rect.height / 2;

    // Fire confetti with options
    confetti({
      ...options,
      origin: {
        x: x / window.innerWidth,
        y: y / window.innerHeight,
      },
    });
  };

  return (
    <p onClick={handleClick} {...props}>
      {children}
    </p>
  );
}

// Set displayName for Confetti component
Confetti.displayName = "Confetti";

export { Confetti, ConfettiButton };
export default Confetti;
