const CompanyImage = ({ src, height, width }) => {
  return (
    <img
      src={src}
      className="shadowa"
      alt="company logo"
      style={{
        height: height ? height : "36px",
        width: width ? width : "36px",
        borderRadius: "10px",
        background: "#FFF",
        padding: "2px",
        objectFit: "cover",
      }}
    />
  );
};

export default CompanyImage;
