import { useEffect, useRef, useState } from "react";
import JobCard from "./JobCard";
import JobPage from "./JobPage";
import Loader from "./Loader";
import { useMediaQuery } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
const Joblist = ({
  jobs,
  loading,
  selectedJob,
  setSelectedJob,
  handleNumChange,
  setJobNum,
  calculatedHeight,
  handleSaveJob,
  savedIds,
  setData,
}) => {
  const isNonMobile = useMediaQuery("(min-width:1024px)");
  // <div style={{ height: calculatedHeight, backgroundColor: "lightblue" }}>

  // </div>

  const popupref = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (popupref.current && !popupref.current.contains(event.target)) {
        setSelectedJob(null);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div>
      {loading ? (
        <div className="d-flex" style={{ gap: "1.5rem" }}>
          <Loader />
        </div>
      ) : (
        <>
          {jobs.length > 0 ? (
            <>
              <div
                className="d-flex"
                style={{
                  gap: "1.5rem",
                }}
              >
                <div
                  style={{
                    width: isNonMobile ? "35.62%" : "100%",
                    gap: "1rem",
                    maxHeight: calculatedHeight,

                    // isNonMobile ? calculatedHeight : "fit-content",
                    overflowY: "auto",
                    //  isNonMobile ? "scroll" : "auto",
                    paddingRight: "0.5rem",
                    paddingBottom: "0.5rem",
                  }}
                  className="d-flex flex-column tablescroll"
                >
                  {jobs.map((job, index) => (
                    <div
                      key={index}
                      onClick={() => {
                        setSelectedJob(job);
                        setJobNum(index);
                      }}
                      className=""
                    >
                      <JobCard job={job} selectedJob={selectedJob} />
                    </div>
                  ))}
                </div>
                {isNonMobile && (
                  <div style={{ width: "64.38%" }}>
                    <JobPage
                      job={selectedJob}
                      calculatedHeight={calculatedHeight}
                      handleNumChange={handleNumChange}
                      isNonMobile={isNonMobile}
                      handleSaveJob={handleSaveJob}
                      savedIds={savedIds}
                      setData={setData}
                    />
                  </div>
                )}
              </div>
              {!isNonMobile && (
                <div
                  className={`mobileJobDetails ${
                    selectedJob ? "showjobdetails" : "d-none"
                  }`}
                  style={{ position: "absolute", paddingTop: "5rem" }}
                >
                  <div
                    style={{
                      background: "#FFF",
                      borderRadius: "5px 5px 0px 0px",
                      overflowY: "visible",
                      height: "100%",
                      maxHeight: "calc(100vh - 90px)",
                    }}
                    ref={popupref}
                  >
                    <div
                      className="space align-items-center"
                      style={{ padding: "1.25rem" }}
                    >
                      <p className="gray95014400">Job Details</p>
                      <CloseIcon
                        className="hoverdarkblacksvg"
                        style={{ cursor: "pointer" }}
                        onClick={() => setSelectedJob(null)}
                      />
                    </div>
                    <JobPage
                      job={selectedJob}
                      calculatedHeight={calculatedHeight}
                      handleNumChange={handleNumChange}
                      isNonMobile={isNonMobile}
                      handleSaveJob={handleSaveJob}
                      savedIds={savedIds}
                    />
                  </div>
                </div>
              )}
            </>
          ) : (
            <p className="black16 text-start">No Jobs</p>
          )}
        </>
      )}
    </div>
  );
};
export default Joblist;
