import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-image-crop/dist/ReactCrop.css";
import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// import usePreventZoom from "./utils/usePreventZoom";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Home from "./pages/Home/Home";
import { useSelector } from "react-redux";
import StudentRegistration from "./pages/StudentRegistration/Index";
import PrimaryRouteLayout from "./layouts/PrimaryRouteLayout";
import Admin from "./pages/Admin/admin";
import CompanyRegister from "./pages/Company/Register";
import EmailPage from "./pages/Company/EmailPage";
import FindCompany from "./pages/Company/FindCompany";
import CreateCompany from "./pages/Company/CreateCompany";
import Role from "./pages/register/Role";
import Profile from "./pages/user/profile";
import Guide from "./pages/GuideLine/Guideline";

import Dashboard from "./pages/Challenge/Dashboard";
import Test from "./pages/Challenge/Challenges";
import Dash from "./components/Company/Dashboard/Dash";
import Create from "./pages/Challenge/Create";
import Main from "./pages/Challenge/Main";
import StudentReg from "./components/StudentRegister/Register/main";

import StudentDashboard from "./pages/StudentDashboard/Dashboard";
import Popup from "./pages/StudentMoreDetails/Pop";
import Challenges from "./pages/StudentDashboard/Challenges/Challenges";
import PreviewPage from "./pages/PreviewPage/PreviewPage";
import Attempt from "./pages/Test/Test";
import Result from "./pages/Test/Results";
import CompanyHome from "./pages/CompanyHome/Home";
import Register from "./pages/Linkedin/register";
import Login from "./pages/Login/StudentLogin/Login";
import EditChallenge from "./pages/Challenge/EditChallenge";
import Jobpost from "./pages/JobPost/Main";
import CreateJob from "./pages/JobPost/CreateJob/CreateJob";
import Editjob from "./pages/JobPost/CreateJob/EditJob";
import Settings from "./pages/Settings/Settings";
import TestMain from "./pages/TestAgain/Main";
import Company from "./pages/CompanyLogin/Login";
import LoginLinkedin from "./pages/Linkedin/login";
import Support from "./pages/Support/support";
import DashboardMain from "./pages/MainDashBoard/DashboardMain";
import TalentPool from "./pages/TalentPool/Main";
import StudentProfilePage from "./pages/StudentProfilePage/StudentProfilePage";
import ShortList from "./pages/TalentPool/ShortListed/Shortlist";
import UserProfile from "./pages/StudentDashboardProfile/UserProfile";
import Preview from "./pages/CompanyPreview/Preview";
import TargetSchool from "./pages/TalentPool/TargetedSchools/Targeted";
import Team from "./pages/TeamPage/Team";
import InviteRegister from "./pages/TeamPage/InviteRegister";
// import Tester from "./pages/Amity/Test";
import Testing from "./pages/Testing";
import CompanyPage from "./pages/CompanyPage/Main";
import CompanyEdit from "./pages/CompanyEdit/Edit";
import Terms from "./pages/Terms/Term";
import Privacy from "./pages/Privacy/Privacy";
import Cookie from "./pages/Cookie/Cookie";
import JobFlow from "./pages/StudentJobFlow/Jobflow";
import SocialMain from "./pages/SocialFeed/SocialMain";
import StudentSettings from "./pages/StudentSettings/Settings";
import NewDashboard from "./pages/Challenge/NewDashboard";

import NewStudentDashboard from "./pages/StudentDashboard/NewDashboard";
import CompanyChat from "./pages/CompanyChat/Main";
import ForgotPassword from "./pages/CompanyLogin/ForgotPassword";
import CreateNewPassword from "./pages/CompanyLogin/CreateNewPassword";
import EventMain from "./pages/Events/Main";
import CreateEvent from "./pages/CreatEvent/Main";
import EditEvent from "./pages/CreatEvent/EditEvent";
import Progress from "./pages/Progress/main";
import UniversityRegister from "./pages/University/Register/Main";
import SelectedView from "./pages/Events/Upcoming/SelectedView";
import ChallengeDetails from "./pages/Challenge/ChallengeDetails";
import Recruiter from "./pages/Profile/Recruiter";
import Details from "./pages/JobDetails/Details";
import NewProfile from "./pages/NewCompanyProfile/Profile";
import Contact from "./pages/Contact/Contact";
import UniversityHome from "./pages/University/Home/Main";
import Toolkit from "./pages/University/Toolkit/Main";
import CareerContact from "./pages/CareerContact/Contact";
import SupportNew from "./pages/SupportNew/SupportNew";
import BookDemo from "./pages/BookDemo/BookDemo";
import FindUni from "./pages/University/Register/FindUni";
import ScrollToTop from "./pages/ScrollTop/TopScroll";
import LatestStudentDashboard from "./pages/NewStudentDashboard/Main";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import NewStudentChallenges from "./pages/Student/Challenge/Main";
import StudentChallengeMain from "./pages/StudentChallenge/Main";
import { disableReactDevTools } from "@fvilers/disable-react-devtools";
import NewResult from "./pages/ChallengeResult/NewResult";
import Solutions from "./pages/NewPages/Page1";
import PublicTour from "./pages/PublicTour/Main";
import EventsHome from "./pages/NewPages/Events/EventsHome";
import AnalyticsHome from "./pages/NewPages/Analytics/AnalyticsHome";
import StudentJobDetails from "./pages/StudentJobFlow/JobDetails";
import MainEventsStudent from "./pages/StudentEventFlow/Main";
import MainEventDetails from "./pages/StudentEventDetails/MainEventDetails";
import StudentGroupMain from "./pages/StudentGroups/Main";
import GroupDetails from "./pages/StudentGroups/GroupDetails/Main";
function App() {
  // disableReactDevTools();
  const UserLogin = localStorage.getItem("userId");
  const OrganiserLogin = useSelector((state) => Boolean(state.uniqueId));
  const [student, setStudent] = useState(null);

  return (
    <>
      <ToastContainer />
      <Router>
        <ScrollToTop student={student} setStudent={setStudent} />
        <Routes>
          {/* <RouterProvider router={router} /> */}
          <Route
            path="/"
            element={
              OrganiserLogin ? (
                <Navigate to="/dashboard" />
              ) : (
                <PrimaryRouteLayout />
              )
            }
          >
            <Route index element={<Home />} />
            <Route path="/home" element={<Home />} />
          </Route>
          <Route
            path="/career-centers/marketing-toolkit/"
            element={<Toolkit />}
          />
          <Route path="/contact" element={<Contact />} />
          <Route path="/company" element={<CompanyHome />} />
          <Route path="/career-centers" element={<UniversityHome />} />
          <Route
            path="/career-center/register"
            element={<UniversityRegister />}
          />
          <Route path="/career-center/find/:uniqueId" element={<FindUni />} />
          <Route path="/career-center/contact" element={<CareerContact />} />
          <Route path="/company/register" element={<CompanyRegister />} />
          <Route path="/company/register/verify" element={<EmailPage />} />
          <Route path="/register/linkedin" element={<Register />} />
          <Route path="/login/linkedin" element={<LoginLinkedin />} />
          <Route path="/Find/:uniqueId" element={<FindCompany />} />

          <Route path="/register" element={<StudentReg />} />

          <Route path="/create/company" element={<CreateCompany />} />
          {/* <Route path="/admin" element={<Admin />} /> */}
          <Route path="/login" element={<Login />} />
          <Route path="/create/company/role" element={<Role />} />
          <Route path="/support" element={<SupportNew />} />
          <Route path="/company/support" element={<SupportNew />} />

          {/* <Route path="/support" element={<Support />} /> */}
          <Route path="/guidelines" element={<Guide />} />
          <Route
            path="/challenge/:uniqueId"
            element={<PreviewPage user={student} />}
          />
          <Route path="/preview/:uniqueId" element={<Preview />} />
          <Route
            path="/challenge/attempt/:uniqueId"
            element={<StudentChallengeMain student={student} />}
          />
          {/* <Route path="/challenge/attempt/:uniqueId" element={<TestMain />} /> */}
          {/* <Route path="/challenge/result/:id" element={<Result />} /> */}
          <Route path="/challenge/result/:id" element={<NewResult />} />
          <Route path="/test" element={<Testing />} />
          {/* <Route path="/company/:uniqueId" element={<CompanyPage />} /> */}
          <Route
            path="/company/edit/:uniqueId"
            element={
              OrganiserLogin ? (
                <CompanyEdit />
              ) : (
                <Navigate to="/company/login" />
              )
            }
          />

          <Route path="/edit/challenge" element={<EditChallenge />} />
          <Route path="/edit/job" element={<Editjob />} />
          {/* <Route path="/challenge/taken/:uniqueId" element={<TestMain />} /> */}
          <Route path="/company/login" element={<Company />} />

          <Route
            path="/student/dashboard"
            element={
              <LatestStudentDashboard
                student={student}
                setStudent={setStudent}
              />
            }
          >
            <Route index element={<SocialMain user={student} />} />
            <Route path="user/:uniqueId" element={<UserProfile />} />
            <Route path="groups" element={<StudentGroupMain />} />
            <Route path="group/:link" element={<GroupDetails />} />
            <Route
              path="challenges"
              element={<NewStudentChallenges student={student} />}
            />
            <Route path="jobs" element={<JobFlow />} />
            <Route
              path="events"
              element={<MainEventsStudent user={student} />}
            />
            <Route
              path="event/:link"
              element={<MainEventDetails user={student} />}
            />
            <Route path="job/:uniqueId" element={<StudentJobDetails />} />
            <Route path="settings" element={<StudentSettings />} />
          </Route>
          {/* <Route path="/new" element={<NewStudentDashboard />} /> */}
          {/* <Route path="/dashboard" element={<Dashboard />} /> */}
          <Route
            path="/dashboard"
            element={
              OrganiserLogin ? (
                <NewDashboard />
              ) : (
                <Navigate to="/company/login" />
              )
            }
          >
            <Route index element={<DashboardMain />} />
            <Route path="u/:url" element={<Recruiter />} />
            <Route path="progress" element={<Progress />} />
            <Route path="book-tour" element={<BookDemo />} />
            <Route path="chat" element={<CompanyChat />} />
            <Route path="company/:uniqueId" element={<NewProfile />} />
            <Route path="events" element={<EventMain />} />
            <Route path="events/details" element={<SelectedView />} />
            <Route path="user/:uniqueId" element={<StudentProfilePage />} />
            <Route path="TalentPool" element={<TalentPool />} />
            <Route path="TalentPool/Short-list" element={<ShortList />} />
            <Route path="TalentPool/TargetSchool" element={<TargetSchool />} />
            <Route path="Challenges" element={<Test />} />
            <Route path="challenge/details" element={<ChallengeDetails />} />
            <Route path="Team" element={<Team />} />
            <Route path="Jobposts" element={<Jobpost />} />
            <Route path="job/details/:uniqueId" element={<Details />} />
            {/* <Route path="create/challenge" element={<Main />} /> */}
            <Route path="settings" element={<Settings />} />
            {/* <Route path="messenger" element={<Tester />} /> */}
          </Route>

          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/cookie" element={<Cookie />} />

          <Route
            path="/create/challenge"
            element={
              OrganiserLogin ? <Main /> : <Navigate to="/company/login" />
            }
          />
          <Route path="/company/book-a-tour" element={<PublicTour />} />

          <Route path="/company/solutions-challenges" element={<Solutions />} />
          <Route path="/company/events" element={<EventsHome />} />
          <Route path="/company/analytics" element={<AnalyticsHome />} />
          <Route
            path="/create/job"
            element={
              OrganiserLogin ? <CreateJob /> : <Navigate to="/company/login" />
            }
          />
          <Route
            path="/create/event"
            element={
              OrganiserLogin ? (
                <CreateEvent />
              ) : (
                <Navigate to="/company/login" />
              )
            }
          />
          <Route
            path="/edit/event"
            element={
              OrganiserLogin ? <EditEvent /> : <Navigate to="/company/login" />
            }
          />
          <Route path="/company/forgot/password" element={<ForgotPassword />} />
          <Route
            path="/company/recover/:uniqueId"
            element={<CreateNewPassword />}
          />
          <Route
            path="/registration/:company/:dsa/:user"
            element={<InviteRegister />}
          />
          <Route
            path="/create/profile/:uniqueId/:dsa/:company/:user"
            element={<Profile />}
          />
          <Route path="/create/company/role/:Role" element={<Profile />} />
          {/* <Route path="/tester" element={<DesignTest />} /> */}
          <Route path="/*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
