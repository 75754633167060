import { useEffect, useState } from "react";
import io from "socket.io-client";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import StudentProfilePage from "../StudentProfilePage/StudentProfilePage";
import Chat from "./Chat";
import { useMediaQuery } from "@mui/material";
import "./main.css";
import { toast } from "react-toastify";
import ChatList from "./ChatList";
const socket = io(`${process.env.REACT_APP_BACKEND_URL}`);

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const CompanyChat = () => {
  const [user, setUser] = useState(null);
  const [otheruser, setOtherUser] = useState(null);
  const id = useSelector((state) => state.uniqueId);
  const navigate = useNavigate();
  const [company, setCompany] = useState();
  const [messages, setMessages] = useState([]);
  const query = useQuery();
  const recipient = query.get("id");
  const [selectedChats, setSelectedChats] = useState([]);
  const [unread, setUnread] = useState([]);
  const [archieved, setArchieved] = useState([]);
  const [starredChats, setStarredChats] = useState([]);
  const isNonMob1024 = useMediaQuery("(min-width:1024px)");
  const isNonMob560 = useMediaQuery("(min-width:560px)");
  const [chatmessages, setChatMessages] = useState([]);
  const [message, setMessage] = useState("");
  const isNonMobile600 = useMediaQuery("(min-width:600px)");
  const isNonMobile660 = useMediaQuery("(min-width:660px)");
  const isNonMobile300 = useMediaQuery("(min-width:300px)");
  const [test, setTest] = useState(false);
  const [search, setSearch] = useState("");
  const [alldata, setAllData] = useState([]);
  const [test2, setTest2] = useState(false);
  const [selected, setSelected] = useState("Inbox");

  const [updatedMessages, setUpdated] = useState([]);

  useEffect(() => {
    const fetchData = async (userId) => {
      try {
        const userResponse = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/get/user/details`,
          { userId, msg: "fjksabnfjkbnasjfbajsbfjasjhfbjahsbjkfbk" }
        );
        setAllData((prev) => [...prev, userResponse.data.user]);
      } catch (error) {
        console.error(error);
      }
    };
    messages.map(async (msg) => {
      const id = msg?.users[0] === user?._id ? msg?.users[1] : msg?.users[0];

      await fetchData(id);
    });
  }, [messages]);

  const handleSendMessage = () => {
    if (message && user && otheruser) {
      socket.emit("sendMessage", {
        userId: user?._id,
        recipient: otheruser?._id,

        message,
        type: otheruser?.uni ? "student" : "organiser",
      });

      setMessage("");
      getMessages();
    } else {
      toast.error("Please type a message first");
    }
  };

  useEffect(() => {
    messages.map((msg) => {
      if (msg?.users?.includes(otheruser?._id)) {
        return setChatMessages(msg);
      }
    });
  }, [messages, setMessages, recipient, test, setTest, otheruser]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (recipient) {
          const check = alldata.find((data) => data?.uniqueId === recipient);
          if (check) {
            setOtherUser(check);
            return;
          } else {
            const userResponse = await axios.post(
              `${process.env.REACT_APP_BACKEND_URL}/get/user/details/uniqueId/company`,
              {
                userId: recipient,
                msg: "fjksabnfjkbnasjfbajsbfjasjhfbjahsbjkfbk",
              }
            );

            if (recipient && userResponse.data.message !== "Found") {
              navigate(-1);
              return;
            }

            setOtherUser(userResponse.data.user);
          }
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [recipient]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await axios
          .post(`${process.env.REACT_APP_BACKEND_URL}/api/get/chatinfo`, {
            userId: user?._id,
          })
          .then((res) => {
            if (res.data?.msg === "success") {
              setStarredChats(res.data?.chatInfo?.starred);
              setUnread(res.data?.chatInfo?.unread);
              setArchieved(res.data?.chatInfo?.archieve);
            }
          });
      } catch (err) {
        console.log(err.message);
      }
    };
    fetchData();
  }, [user, test2]);

  useEffect(() => {
    let updatedMessages = [];

    if (selected === "Inbox") {
      updatedMessages = messages;
    } else if (selected === "Sent") {
      if (messages.length > 0) {
        updatedMessages = messages.filter((msg) =>
          msg?.messages?.some((m) => m.sender === user?._id)
        );
      }
    } else if (selected === "Archive") {
      updatedMessages = messages.filter((msg) => archieved?.includes(msg?._id));
    } else if (selected === "Starred") {
      updatedMessages = messages.filter((msg) =>
        starredChats?.includes(msg?._id)
      );
    } else if (selected === "Unread") {
      updatedMessages = messages.filter((msg) => unread?.includes(msg?._id));
    }
    setUpdated(updatedMessages);
  }, [selected, messages]);

  const getMessages = async () => {
    await axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/get/user/message`, {
        userId: user?._id,
      })
      .then((res) => {
        setMessages(res.data.chats);
        setUpdated(res.data.chats);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    socket.on("receiveMessage", (message) => {
      getMessages();
    });

    return () => {
      socket.off("receiveMessage");
    };
  }, [user]);

  useEffect(() => {
    setSelectedChats([]);
  }, [otheruser, recipient]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userInfoResponse = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/getUserInfo/uniqueId`,
          { email: id }
        );

        if (userInfoResponse.data.message === "Found") {
          setUser(userInfoResponse.data.user);
        }

        const companyInfoResponse = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/getCompanyInfo`,
          { id: userInfoResponse.data.user.company }
        );
        if (companyInfoResponse.data.msg === "Logout") {
          navigate("/company/register");
          return;
        }
        setCompany(companyInfoResponse.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [id]);

  useEffect(() => {
    getMessages();
  }, [user]);

  const handleShortList = async (ida) => {
    await axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/api/company/shortlist/student`,
        {
          id: ida,
          companyId: company.uniqueId,
          msg: "sjdfhajksdfjkdashfjhasdjfhaksfasjk",
          type: "Talentpool",
          owner: user._id,
        }
      )
      .then((res) => {
        if (res.data.msg === "success") {
          toast.success("Added to Shortlist");
        }
      });
  };

  return (
    <div>
      {recipient ? (
        <div className="d-flex ">
          <Chat
            otheruser={otheruser}
            messages={messages}
            message={message}
            setMessage={setMessage}
            handleSendMessage={handleSendMessage}
            navigate={navigate}
            isNonMob560={isNonMob560}
            isNonMobile600={isNonMobile600}
            chatmessages={chatmessages}
            user={user}
            setTest2={setTest2}
            handleShortList={handleShortList}
          />

          {isNonMob1024 && recipient && (
            <div
              style={{
                minWidth: "25.313rem",
                maxWidth: "25.313rem",
                height: "calc(100vh - 4.375rem)",
                overflowY: "auto",
                border: "",
              }}
            >
              <StudentProfilePage recipient={recipient} TRUE={true} />
            </div>
          )}
        </div>
      ) : (
        <div style={{ height: "100%" }}>
          <ChatList
            messages={updatedMessages}
            setSelectedChats={setSelectedChats}
            selectedChats={selectedChats}
            isNonMob1024={isNonMob1024}
            user={user}
            navigate={navigate}
            starredChats={starredChats}
            setStarredChats={setStarredChats}
            isNonMobile600={isNonMobile600}
            isNonMobile300={isNonMobile300}
            setTest={setTest}
            search={search}
            setSearch={setSearch}
            alldata={alldata}
            setTest2={setTest2}
            test2={test2}
            selected={selected}
            setSelected={setSelected}
            isNonMobile660={isNonMobile660}
          />
        </div>
      )}
    </div>
  );
};
export default CompanyChat;
