import { useEffect, useState } from "react";
import Group from "./GroupDetail";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
const GroupDetails = () => {
  const { link } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const fetchGroup = async () => {
    setLoading(true);
    await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/get/group/detail/${link}`
    );
  };

  useEffect(() => {
    if (link) {
      fetchGroup();
    } else {
      navigate(-1);
    }
  }, [link]);
  return (
    <div className="marginsleftrightCompany" style={{ paddingTop: "1.875rem" }}>
      <div className="center">
        <div className="headerCompany">
          <p className="studentHeading">Groups Details</p>
          {loading ? (
            <div
              className="center"
              style={{ position: "fixed", top: "50%", left: "50%" }}
            >
              <div className="loader" />
            </div>
          ) : (
            <div
              className="d-flex "
              style={{ marginTop: "1.875rem", gap: "1.875rem" }}
            >
              <div style={{ width: "100%" }}>
                <Group />
              </div>
              <div
                className="rounded-2"
                style={{
                  minWidth: "300px",
                  maxWidth: "300px",
                  background: "#FFF",
                  padding: "1rem",
                }}
              >
                fadsfasdf
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default GroupDetails;
