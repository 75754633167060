import "./login.css";
import logo from "../../../assets/images/logo.png";
import circle from "../../../assets/images/RegisterCompany.png";
import { useMediaQuery } from "@mui/material";
import { useState } from "react";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setLogin } from "../../../state";
import axios from "axios";
const Login = () => {
  const isNonMobile768 = useMediaQuery("(min-width: 768px)");
  const isNonMobile1024 = useMediaQuery("(min-width: 1024px)");
  const isNonMobile1100 = useMediaQuery("(min-width: 1100px)");
  const isNonMobile522 = useMediaQuery("(min-width: 522px)");
  const [email, setEmail] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");

  const [error, setError] = useState("");
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();
    toast.warning("Student Login is under maintainance atm.");
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   if (!email) {
  //     toast.error("Please enter email address ");
  //     return;
  //   }
  //   if (!password) {
  //     toast.error("Please enter Password");
  //     return;
  //   }
  //   setLoader(true);
  //   await axios
  //     .post(`${process.env.REACT_APP_BACKEND_URL}/api/student/login`, {
  //       email: email,
  //       password: password,
  //     })
  //     .then((res) => {
  //       if (res.data.msg === "success") {
  //         // Save the token in localStorage
  //         localStorage.setItem("token", res.data.token);
  //         localStorage.setItem("userId", res.data.user.uniqueId);

  //         // Dispatch login action with user data

  //         // Navigate to the student dashboard
  //         setTimeout(() => {
  //           navigate("/student/dashboard");
  //           window.scrollTo(0, 0);
  //         }, 500);
  //       } else if (res.data.msg === "notreg") {
  //         setError("Email Not Registered");
  //       } else if (res.data.msg === "wrongpass") {
  //         setError("Incorrect Password");
  //       }
  //       setLoader(false);
  //     })
  //     .catch((err) => {
  //       setError("Error Logging in");
  //       console.log(err);
  //       setLoader(false);
  //     });
  // };

  return (
    <div className="loginbackground space" style={{ flexDirection: "column" }}>
      <div className={`headerBackgroundChange ${!isNonMobile768 && "shadowa"}`}>
        <div className={`marginsleftrightCompany  `}>
          <div className="center" style={{ width: "100%" }}>
            <div className="headerCompany ">
              <div className={`space studentRegMarginTop `}>
                <div className="logoCompany">
                  <img
                    src={logo}
                    alt="logo"
                    height="36px"
                    width="36px"
                    style={{ cursor: "pointer" }}
                    onClick={() => navigate("/")}
                  />
                </div>
                <button
                  className="newsupportbutton supportbuttontext btn border-0 d-flex"
                  style={{
                    alignItems: "center",
                    gap: "0.5rem",
                  }}
                  onClick={() => navigate("/support")}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M9.83021 6.69935C9.51061 6.73619 9.2694 7.0068 9.2694 7.32852C9.2694 7.65024 9.51061 7.92084 9.83021 7.95768C9.99813 7.96001 10.1597 7.89376 10.2777 7.77423C10.3957 7.65469 10.4598 7.49222 10.4552 7.32435C10.4507 6.98105 10.1735 6.70384 9.83021 6.69935Z"
                      fill="#101828"
                    />
                    <path
                      d="M9.83021 9.09935C9.66376 9.09708 9.50347 9.1622 9.38577 9.27991C9.26806 9.39761 9.20294 9.5579 9.20521 9.72435V12.3243C9.20521 12.6695 9.48503 12.9493 9.83021 12.9493C10.1754 12.9493 10.4552 12.6695 10.4552 12.3243V9.74102C10.4597 9.57239 10.3959 9.4091 10.2781 9.28826C10.1604 9.16742 9.9989 9.09929 9.83021 9.09935Z"
                      fill="#101828"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M9.83021 1.66602C5.32639 1.67061 1.67647 5.32053 1.67188 9.82435C1.67188 14.3301 5.32449 17.9827 9.83021 17.9827C14.3359 17.9827 17.9885 14.3301 17.9885 9.82435C17.9839 5.32053 14.334 1.67061 9.83021 1.66602ZM9.83021 16.7327C6.01484 16.7327 2.92188 13.6397 2.92188 9.82435C2.92188 6.00898 6.01484 2.91602 9.83021 2.91602C13.6456 2.91602 16.7385 6.00898 16.7385 9.82435C16.7339 13.6378 13.6437 16.7281 9.83021 16.7327Z"
                      fill="#101828"
                    />
                  </svg>
                  Support
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`marginsleftrightCompany  `}>
        <div className="center" style={{ width: "100%" }}>
          <div className="headerCompany ">
            <div className={`${isNonMobile1024 ? "space" : ""}`}>
              <div style={{ width: isNonMobile522 ? "440px" : "100%" }}>
                <form onSubmit={(e) => handleSubmit(e)}>
                  <p className="registeration">Log In</p>
                  <p style={{ marginTop: "6px" }} className="headersmalltext">
                    Welcome back! Please enter your details.
                  </p>
                  <div style={{ marginTop: "1.25rem" }}>
                    <p className="Email">Email</p>
                    <input
                      value={email}
                      type="email"
                      onChange={(e) => setEmail(e.target.value)}
                      className="rounded-3   col-md-12 col-12  newinputs"
                      style={{ marginTop: "12px" }}
                    />
                  </div>
                  <div style={{ marginTop: "1.25rem" }}>
                    <p className="Email">Password</p>
                    <div className="position-relative">
                      <input
                        value={password}
                        type={showPassword ? "text" : "password"}
                        onChange={(e) => setPassword(e.target.value)}
                        className="rounded-3 newinputs mt-3 col-md-12 col-12 "
                        style={{ marginTop: "14px", maxWidth: "440px" }}
                      />
                      <div
                        className="position-absolute end-10 top-50 translate-middle-y"
                        style={{
                          right: "10px",
                          cursor: "pointer",
                          maxWidth: "440px",
                        }}
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? (
                          <VisibilityOffOutlinedIcon />
                        ) : (
                          <RemoveRedEyeOutlinedIcon />
                        )}
                      </div>
                    </div>
                  </div>
                  {error && (
                    <p
                      className="headersmalltext"
                      style={{ marginTop: "0.5rem", color: "red" }}
                    >
                      {error}
                    </p>
                  )}
                  <div
                    className="d-flex"
                    style={{
                      alignItems: "center",
                      marginTop: "0.5rem",
                      rowGap: "0.5rem",
                      justifyContent: "end",
                    }}
                  >
                    <p
                      className="headersmalltext"
                      style={{ color: "#E31B54", cursor: "pointer" }}
                    >
                      Forgot password?
                    </p>
                  </div>
                  <button
                    className="continuebutton col-md-12 col-12"
                    style={{ marginTop: "1.25rem" }}
                    type="submit"
                    disabled={loader}
                  >
                    Sign in
                  </button>
                  <p
                    className="didnt text-center"
                    style={{ marginTop: "1.5rem" }}
                  >
                    Don't have an account?
                    <span onClick={() => navigate("/register")}> Sign up</span>
                  </p>
                </form>
              </div>
              {isNonMobile1100 && (
                <div style={{ width: "440px" }}>
                  <img src={circle} alt="login page" className="img-fluid" />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="marginsleftrightCompany ">
        <div className="center" style={{ width: "100%", height: "100%" }}>
          <div className="headerCompany " style={{ height: "100%" }}>
            <div
              className="d-flex mb-4"
              style={{ justifyContent: isNonMobile1024 ? "end" : "center" }}
            >
              <div className="d-flex">
                <button
                  className="footertextnew btn"
                  style={{ cursor: "pointer", textDecoration: "none" }}
                  onClick={() => window.open("/privacy", "_blank")}
                >
                  Privacy Policy
                </button>
                <button
                  className="footertextnew btn"
                  style={{ cursor: "pointer", textDecoration: "none" }}
                  onClick={() => window.open("/terms", "_blank")}
                >
                  Terms of Use
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Login;
