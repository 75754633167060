import "./tasksection.css";

import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
const TaskSection = ({
  StartChallenge,
  newTasks,
  color,
  test,
  Userloggedin,
  uniqueId,
  checker,
  no,
  setPreview,
}) => {
  const [showIndex, setShowIndex] = useState(0);
  const navigate = useNavigate();
  return (
    <div className="marginsleftrightCompany" id="tasks">
      <div className="d-flex" style={{ justifyContent: "center" }}>
        <div className={`headerCompany`} style={{ padding: "100px 0px" }}>
          <p className="text-center registeration">Your tasks</p>
          <p style={{ marginTop: "0.75rem" }} className="g95016400">
            Here you'll discover a breakdown of tasks designed to test your
            skills.
            <br /> <span>Let's explore what awaits you.</span>
          </p>

          <div style={{ marginTop: "3.75rem" }}>
            {newTasks.map((task, index) => (
              <div
                className="center w-100"
                key={index}
                style={{ marginTop: index === 0 ? "0rem" : "1rem" }}
              >
                <div
                  className="newTasksnewdivouterpreview "
                  style={{
                    borderLeft: `4px solid ${color}`,
                    width: "100%",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (showIndex !== index) {
                      setShowIndex(index);
                    } else {
                      setShowIndex(-1);
                    }
                  }}
                >
                  <div className="space w-100" style={{ gap: "1rem" }}>
                    <p className="tasklisttasktitle">
                      Task {index + 1} :{" "}
                      <span style={{ color: color }}>{task?.taskTitle}</span>
                    </p>
                    <div>
                      {showIndex === index ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </div>
                  </div>
                  <div
                    className="transitiona"
                    style={{
                      maxHeight: showIndex === index ? "500px" : "0px", // Set an appropriate max-height value
                      opacity: showIndex === index ? 1 : 0,
                    }}
                  >
                    <div
                      className="informationtext tablescroll"
                      style={{
                        maxHeight: "310px",
                        overflow: "auto",
                        background: "#FFF",
                        marginTop: "0.75rem",

                        borderRadius: "0px 0px 5px 5px",
                      }}
                      dangerouslySetInnerHTML={{ __html: task?.taskBackground }}
                    />
                    <div
                      className="d-flex flex-wrap"
                      style={{ marginTop: "0.75rem", gap: "1rem" }}
                    >
                      <div
                        className="d-flex flex-wrap"
                        style={{ gap: "0.75rem", alignItems: "center" }}
                      >
                        <div
                          className="shadowa center rounded-3"
                          style={{
                            height: "36px",
                            width: "36px",
                            background: "#FFF",
                            padding: "0.5rem",
                            alignItems: "center",
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="21"
                            height="20"
                            viewBox="0 0 21 20"
                            fill="none"
                          >
                            <path
                              d="M7.70768 6.32533C7.46142 6.09586 7.07767 6.10263 6.83966 6.34064C6.60166 6.57865 6.59488 6.9624 6.82435 7.20866L9.97435 10.3587V14.267C9.97435 14.6122 10.2542 14.892 10.5993 14.892C10.9445 14.892 11.2243 14.6122 11.2243 14.267V10.1003C11.2242 9.93462 11.1583 9.77576 11.041 9.65866L7.70768 6.32533Z"
                              fill="#0C111D"
                            />
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M8.83268 1.66699H12.366C14.1341 1.66699 15.8298 2.36937 17.0801 3.61961C18.3303 4.86986 19.0327 6.56555 19.0327 8.33366V11.867C19.0327 15.5489 16.0479 18.5337 12.366 18.5337H8.83268C5.15078 18.5337 2.16602 15.5489 2.16602 11.867V8.33366C2.16602 4.65176 5.15078 1.66699 8.83268 1.66699ZM12.366 17.2837C15.3557 17.2791 17.7781 14.8566 17.7827 11.867V8.33366C17.7781 5.34402 15.3557 2.92158 12.366 2.91699H8.83268C5.84304 2.92158 3.4206 5.34402 3.41602 8.33366V11.867C3.4206 14.8566 5.84304 17.2791 8.83268 17.2837H12.366Z"
                              fill="#0C111D"
                            />
                          </svg>
                        </div>
                        <p className="black14">
                          Expected time to complete:{" "}
                          <span className="black14" style={{ color: color }}>
                            {task?.time} mins
                          </span>
                        </p>
                      </div>
                      <div
                        className="d-flex flex-wrap"
                        style={{ gap: "0.75rem", alignItems: "center" }}
                      >
                        <div
                          className="shadowa center rounded-3"
                          style={{
                            height: "36px",
                            width: "36px",
                            background: "#FFF",
                            padding: "0.5rem",
                            alignItems: "center",
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="21"
                            height="20"
                            viewBox="0 0 21 20"
                            fill="none"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M15.9308 2.5H10.9975C9.61674 2.5 8.49745 3.61929 8.49745 5V14.4C8.49745 15.7807 9.61674 16.9 10.9975 16.9H15.9308C17.3115 16.9 18.4308 15.7807 18.4308 14.4V5C18.4308 3.61929 17.3115 2.5 15.9308 2.5ZM9.78079 9.16667H10.4641C10.6942 9.16667 10.8808 9.35321 10.8808 9.58333V9.85C10.8808 10.0801 10.6942 10.2667 10.4641 10.2667H9.78079V9.16667ZM15.9308 15.6667C16.6211 15.6667 17.1808 15.107 17.1808 14.4167V5C17.1808 4.30964 16.6211 3.75 15.9308 3.75H10.9974C10.3071 3.75 9.74745 4.30964 9.74745 5V7.9H10.4308C11.3513 7.9 12.0974 8.64619 12.0974 9.56667V9.83333C12.0974 10.7538 11.3513 11.5 10.4308 11.5H9.74745V14.4167C9.74745 15.107 10.3071 15.6667 10.9974 15.6667H15.9308Z"
                              fill="#0C111D"
                            />
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M2.17245 5.35C2.07205 3.90206 3.15862 2.64432 4.60578 2.53333C6.04642 2.65719 7.11978 3.91625 7.01411 5.35833V13.0833C7.02358 13.4842 6.91333 13.8788 6.69745 14.2167L5.13912 16.625C5.02873 16.8229 4.81988 16.9455 4.59328 16.9455C4.36668 16.9455 4.15783 16.8229 4.04745 16.625L2.48912 14.2167C2.27814 13.8766 2.1683 13.4835 2.17245 13.0833V5.35ZM5.63912 13.5333C5.72283 13.3983 5.7662 13.2422 5.76412 13.0833L5.78079 5.35C5.78912 4.5 5.24745 3.78333 4.60579 3.78333C3.97245 3.78333 3.43079 4.51667 3.43079 5.35V13.0833C3.42368 13.2406 3.46124 13.3966 3.53912 13.5333L4.58912 15.2L5.63912 13.5333Z"
                              fill="#0C111D"
                            />
                            <path
                              d="M4.59745 4.31667C4.25227 4.31667 3.97245 4.59649 3.97245 4.94167V7.825C3.97245 8.17018 4.25227 8.45 4.59745 8.45C4.94263 8.45 5.22245 8.17018 5.22245 7.825V4.94167C5.22472 4.77522 5.1596 4.61493 5.04189 4.49723C4.92419 4.37952 4.7639 4.3144 4.59745 4.31667Z"
                              fill="#0C111D"
                            />
                            <path
                              d="M15.7474 5.11667H12.9558C12.6106 5.11667 12.3308 5.39649 12.3308 5.74167C12.3308 6.08684 12.6106 6.36667 12.9558 6.36667H15.7474C16.0926 6.36667 16.3724 6.08684 16.3724 5.74167C16.3724 5.39649 16.0926 5.11667 15.7474 5.11667Z"
                              fill="#0C111D"
                            />
                          </svg>
                        </div>
                        <p className="black14">
                          Type of questions:{" "}
                          <span className="black14" style={{ color: color }}>
                            Multiple Choice
                          </span>
                        </p>
                      </div>
                    </div>
                    {index === 0 && (
                      <button
                        className="continuebutton"
                        style={{
                          maxHeight: "40px",
                          border: "none",
                          background: color,
                          margin: "0.75rem 0rem",
                          position: "relative",
                          zIndex: 20,
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          if (no) {
                            setPreview(true);
                          } else {
                            if (Userloggedin && !checker) {
                              StartChallenge();
                            } else if (Userloggedin && checker) {
                              navigate("/dashboard/Challenges");
                            } else {
                              navigate("/login");
                            }
                          }
                        }}
                      >
                        {test
                          ? Userloggedin
                            ? "Start Challenge"
                            : "Signin to start"
                          : "Start Challenge"}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
export default TaskSection;
