import GroupCard from "./GroupCard";

const ExploreGroups = ({ groups }) => {
  return (
    <div className="d-flex " style={{ gap: "1.875rem" }}>
      <div style={{ width: "100%" }}>
        <div
          className="d-grid"
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
            gap: "1.5rem",
            width: "100%",
          }}
        >
          {groups.map((grp, index) => (
            <div key={index}>
              <GroupCard group={grp} />
            </div>
          ))}
        </div>
      </div>
      <div
        className="rounded-2"
        style={{
          minWidth: "300px",
          maxWidth: "300px",
          background: "#FFF",
          padding: "1rem",
        }}
      >
        fadsdfasfasdfas
      </div>
    </div>
  );
};
export default ExploreGroups;
