import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useEffect, useRef, useState } from "react";
import Comment from "./Comments";

const PostComments = ({
  comments,
  setCommentOption,
  commentOption,
  getTimeElapsed,
  student,
  token,
  post,
  setComments,
  setTotalComments,
  noMedia,
  handleReportComment,
}) => {
  const [popup, setPopup] = useState(false);
  const popupref = useRef(null);

  const [commentstoshow, setCommentsToShow] = useState(
    comments ? comments : []
  );

  useEffect(() => {
    if (comments && commentstoshow) {
      setCommentsToShow(comments);
    }
  }, [comments, commentstoshow]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });

    function handleClickOutside(event) {
      if (popupref.current && !popupref.current.contains(event.target)) {
        setPopup(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div>
      {/* <div className="d-flex ">
        <div style={{ position: "relative" }}>
          {" "}
          <div
            className="d-flex graybody14500 align-items-center hoverred"
            style={{ gap: "6px", cursor: "pointer" }}
            onClick={() => {
              setPopup(!popup);
            }}
          >
            {commentOption}{" "}
            <ArrowDropDownIcon
              style={{
                height: "20px",
                width: "20px",
                transform: popup ? "rotate(180deg)" : "",
              }}
            />
          </div>
          {popup && (
            <div
              className=" shadowa"
              style={{
                position: "absolute",
                top: "100%",
                background: "#FFF",

                zIndex: 1,
                minWidth: "140px",
                borderRadius: "5px",
              }}
              ref={popupref}
            >
              <button
                className="dropdowntopbutton "
                onClick={() => {
                  setCommentOption("Most recent");
                  setPopup(false);
                }}
              >
                Most recent
              </button>
              <button
                className="dropdownbottombutton"
                onClick={() => {
                  setCommentOption("All Comments");
                  setPopup(false);
                }}
              >
                All Comments
              </button>
            </div>
          )}
        </div>
      </div> */}

      {commentstoshow && (
        <div
          className="d-flex flex-column"
          style={{
            // padding: noMedia ? "" : "20px",
            marginTop: "20px",
            gap: "1.25rem",
          }}
        >
          {commentstoshow.map((comment, index) => (
            <div key={index}>
              <Comment
                comment={comment}
                getTimeElapsed={getTimeElapsed}
                student={student}
                token={token}
                post={post}
                setComments={setComments}
                setCommentsToShow={setCommentsToShow}
                setTotalComments={setTotalComments}
                handleReportComment={handleReportComment}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default PostComments;
