import ChallengeIcon from "../../assets/images/icons/ChallengeIcon";
import Marquee from "react-fast-marquee";
import allskills from "../../assets/images/allskills.svg";
import allskills2 from "../../assets/images/allskills.png";
const Fourth = ({ isNon }) => {
  return (
    <div style={{ padding: "8.125rem 0rem" }}>
      <div className="marginsleftrightCompany">
        <div className="center">
          <div className="headerCompany">
            <div className="center align-items-center flex-column">
              <div className="d-flex">
                <div
                  className="center align-items-center"
                  style={{
                    padding: "8px",
                    borderRadius: "50%",
                    background: "rgba(227, 27, 84, 0.10)",
                  }}
                >
                  <ChallengeIcon />{" "}
                </div>
              </div>
              <p
                className="text-center itsearly "
                style={{ maxWidth: "534px", width: "100%" }}
              >
                Skills-first campus recruiting
              </p>
              <p
                className="text-center gray95020400"
                style={{
                  margin: "1rem 0rem",
                  fontFamily: "Poppins",
                  maxWidth: "1058px",
                }}
              >
                Through challenges, students learn and apply{" "}
                <span
                  className="gray95016400"
                  style={{ fontFamily: "Poppins", fontWeight: 500 }}
                >
                  job-specific
                </span>{" "}
                skills for different industries and career paths. This hands-on{" "}
                <span
                  className="gray95016400"
                  style={{ fontFamily: "Poppins", fontWeight: 500 }}
                >
                  pre-skilling approach
                </span>{" "}
                ensures candidates are ready with the practical experience your
                company needs.
              </p>
              <div
                className="d-flex flex-wrap gray95016500"
                style={{ gap: "1.25rem", fontWeight: 600 }}
              >
                <p className="gray95016500" style={{ fontWeight: 600 }}>
                  24+ Career Paths
                </p>
                |{" "}
                <p className="gray95016500" style={{ fontWeight: 600 }}>
                  {" "}
                  300+ Skills
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          marginTop: "5rem",

          overflow: "hidden",
        }}
      >
        <Marquee pauseOnHover>
          <img
            src={isNon ? allskills : allskills2}
            alt="all skills "
            style={{ maxHeight: "202px" }}
          />
        </Marquee>
      </div>
    </div>
  );
};

export default Fourth;
