import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import EventDetails from "../StudentEventFlow/EventDetails";
import sample from "../../assets/images/eventsample.png";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";

import FavoriteIcon from "@mui/icons-material/Favorite";
import CompanyImage from "../../components/Company/CompanyImage";
const MainEventDetails = ({ user }) => {
  const { link } = useParams();
  const navigate = useNavigate();
  const [event, setEvent] = useState(null);
  const [similar, setSimilar] = useState([]);
  const [question, setQuestion] = useState("");
  const [loading, setLoading] = useState(false);
  const [questionLoading, setQuestionLoading] = useState(false);
  const fetchEvent = async () => {
    if (!loading) {
      setLoading(true);

      await axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/api/get/event`,
          {
            link: link,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((res) => {
          if (res?.data?.msg === "success") {
            setEvent(res.data.event);
            setSimilar(res.data.similar);
          } else if (res.data.msg === "No event") {
            navigate(-1);
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error("Error fetching Event");
          navigate(-1);
        });
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!link) {
      navigate(-1);
    } else {
      fetchEvent();
    }
  }, []);

  const handleSendQuestion = async () => {
    if (!questionLoading) {
      setQuestionLoading(true);
      await axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/api/event/post/question`,
          {
            question,
            eventId: event?.uniqueId,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((res) => {
          if (res?.data?.msg === "success") {
            event.questions.push(res?.data?.newQuestion);
            setQuestion("");
            toast.success("Question Submitted Successfully");
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error("Error posting Question");
        });
      setQuestionLoading(false);
    }
  };
  const [upvoteLoading, setUpvoteLoading] = useState(false);
  const handleUpvote = async (id) => {
    if (!upvoteLoading) {
      setUpvoteLoading(true);

      // Find the question by uniqueId
      const foundQuestion = event.questions.find((que) => que.uniqueId === id);
      if (!foundQuestion) {
        setUpvoteLoading(false);
        return;
      }

      // Check if the user has already upvoted
      const hasUpvoted = foundQuestion.upvotes.includes(user?._id);

      // Toggle the vote
      const newUpvotes = hasUpvoted
        ? foundQuestion.upvotes.filter((upvote) => upvote !== user?._id) // Remove user ID from upvotes
        : [...foundQuestion.upvotes, user?._id]; // Add user ID to upvotes

      // Update the event state
      setEvent((prevEvent) => ({
        ...prevEvent,
        questions: prevEvent.questions.map((que) =>
          que.uniqueId === id
            ? { ...que, upvotes: newUpvotes } // Update the upvotes of the found question
            : que
        ),
      }));

      // Optionally, you can send this to the backend to persist the upvote change
      try {
        await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/event/upvote`,
          {
            questionId: id,
            userId: user?._id,
            upvoted: !hasUpvoted,
            eventId: event.uniqueId,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
      } catch (err) {
        console.error("Error in upvoting:", err);
        toast.error("Failed to update upvote.");
      }

      setUpvoteLoading(false);
    }
  };

  const truncateString = (str, maxLength) => {
    if (str.length > maxLength) {
      return str.substring(0, maxLength) + "...";
    } else {
      return str;
    }
  };

  return (
    <div className="marginsleftrightCompany" style={{ paddingTop: "1.875rem" }}>
      <div className="center">
        <div className="headerCompany ">
          {loading ? (
            <div
              className="center align-items-center"
              style={{ position: "absolute", top: "50vh", left: "50vw" }}
            >
              <div className="loader" />{" "}
            </div>
          ) : event ? (
            <div>
              {" "}
              <p className="studentHeading">Event Details</p>
              <div
                className="d-flex"
                style={{ gap: "1.875rem", marginTop: "1.875rem" }}
              >
                <div style={{ width: "100%" }}>
                  <div
                    style={{
                      background: "#FFF",
                      borderRadius: "10px 10px 5px 5px",
                    }}
                  >
                    <EventDetails
                      event={event}
                      sample={sample}
                      user={user}
                      setEvents={setEvent}
                      fullview={true}
                    />
                  </div>
                  <div
                    style={{
                      margin: "1.5rem 0rem",
                      background: "#FFF",
                      borderRadius: "5px",
                      padding: "1.5rem",
                    }}
                  >
                    <p className="filmo16">Most asked Questions</p>

                    <div
                      style={{
                        width: "100%",
                        height: "1px",
                        background: "#EAECF0",
                        margin: "1.25rem 0rem",
                      }}
                    />
                    {event.questions.length > 0 ? (
                      <div
                        className="d-flex flex-column tablescroll"
                        style={{
                          gap: "1rem",
                          maxHeight: "400px",
                          overflowY: "auto",
                          paddingRight: "4px",
                        }}
                      >
                        {event.questions.map((quest, index) => (
                          <div key={index}>
                            <div
                              className="space flex-wrap align-items-center"
                              style={{ gap: "1rem" }}
                            >
                              <p
                                className="gray95014400"
                                style={{ fontWeight: 500 }}
                              >
                                Question {index + 1}
                              </p>
                              <div
                                className="d-flex align-items-center"
                                style={{ gap: "0.5rem" }}
                              >
                                <div
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    handleUpvote(quest.uniqueId);
                                  }}
                                >
                                  {quest?.upvotes.includes(user?._id) ? (
                                    <FavoriteIcon
                                      style={{
                                        color: "#E31b54",
                                        height: "20px",
                                        width: "20px",
                                      }}
                                    />
                                  ) : (
                                    <FavoriteBorderIcon
                                      className="hoverred"
                                      style={{ height: "20px", width: "20px" }}
                                    />
                                  )}
                                </div>
                                <p className="gray95014400">
                                  {quest.upvotes.length} Upvote
                                  {quest.upvotes.length === 1 ? "" : "s"}
                                </p>
                              </div>
                            </div>
                            <p
                              className="gray80014400"
                              style={{
                                marginTop: "0.75rem",
                                borderRadius: "5px",
                                background: "#F9FAFB",
                                padding: "0.75rem",
                                border: "1px solid #EAECF0",
                              }}
                            >
                              {quest?.text}
                            </p>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <p className="gray80014400">
                        No Questions yet, you can ask your question below
                      </p>
                    )}
                    <div style={{ marginTop: "2rem" }}>
                      <p className="gray95016500" style={{ fontWeight: 600 }}>
                        Ask your question for the Q/A
                      </p>
                      <div
                        className="d-flex flex-column gap-3"
                        style={{
                          marginTop: "1.5rem",
                          border: "1px solid #D0D5DD",
                          borderRadius: "8px",
                          padding: "14px",
                          position: "relative",
                          boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                        }}
                      >
                        {questionLoading && (
                          <div
                            className="center align-items-center"
                            style={{
                              position: "absolute",
                              width: "100%",
                              height: "100%",
                              zIndex: 2,
                              background: "rgba(255,255,255,0.5)",
                              top: 0,
                              left: 0,
                            }}
                          >
                            <div className="line-loader"></div>
                          </div>
                        )}
                        <textarea
                          className="newinputs"
                          style={{
                            border: "none",
                            outline: "none",
                            boxShadow: "none",
                            padding: "0rem",
                            resize: "none",
                            height: "auto",
                            maxHeight: "300px",
                            boxSizing: "border-box",
                            overflowY: "auto", // Enable scrolling when the content exceeds 300px
                          }}
                          placeholder="When does application start?"
                          value={question}
                          onChange={(e) => setQuestion(e.target.value)}
                        />
                        <div className="d-flex justify-content-end">
                          <button
                            className="continuebutton"
                            onClick={() => {
                              handleSendQuestion();
                            }}
                            disabled={questionLoading}
                          >
                            Send
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {(event.schoolYear.length > 0 ||
                    event.relevantCourses.length > 0 ||
                    event.targetGroups.length > 0) && (
                    <div
                      style={{
                        background: "#FFF",
                        borderRadius: "5px",
                        padding: "1.5rem",
                        marginBottom: "1.5rem",
                      }}
                    >
                      <p className="gray95016500" style={{ fontWeight: 600 }}>
                        Requirements
                      </p>
                      <div
                        className="space flex-wrap gap-3"
                        style={{ marginTop: "1.5rem" }}
                      >
                        {event.schoolYear.length > 0 && (
                          <div>
                            <p className="gray70014400">Schools Year</p>
                            <div
                              className="center flex-wrap gap-2"
                              style={{ marginTop: "0.75rem" }}
                            >
                              {event.schoolYear.map((school, index) => (
                                <p
                                  className="gray95014400"
                                  style={{ fontWeight: 500 }}
                                  key={index}
                                >
                                  {school}
                                </p>
                              ))}
                            </div>
                          </div>
                        )}

                        {event.relevantCourses.length > 0 && (
                          <div>
                            <p className="gray70014400">Relevant Courses </p>
                            <div
                              className="center flex-wrap gap-2"
                              style={{ marginTop: "0.75rem" }}
                            >
                              {event.relevantCourses.map((school, index) => (
                                <p
                                  className="gray95014400"
                                  style={{ fontWeight: 500 }}
                                  key={index}
                                >
                                  {school}
                                </p>
                              ))}
                            </div>
                          </div>
                        )}
                        {event.targetGroups.length > 0 && (
                          <div>
                            <p className="gray70014400"> Targeted Schools </p>
                            <div
                              className="center flex-wrap gap-2"
                              style={{ marginTop: "0.75rem" }}
                            >
                              {event.targetGroups.map((school, index) => (
                                <p
                                  className="gray95014400"
                                  style={{ fontWeight: 500 }}
                                  key={index}
                                >
                                  {school}
                                </p>
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  <div
                    style={{
                      background: "#FFF",
                      borderRadius: "5px",
                      padding: "1.5rem",
                      marginBottom: "1.5rem",
                    }}
                  >
                    <p className="gray95014400" style={{ fontWeight: 500 }}>
                      Admin & Speakers
                    </p>
                    <div
                      className="d-flex flex-wrap align-items-center"
                      style={{ gap: "1.5rem", marginTop: "0.75rem" }}
                    >
                      {event.addedUsers.map((speaker, index) =>
                        speaker?.name ? (
                          <div
                            key={index}
                            className="d-flex align-items-center"
                            style={{ gap: "0.75rem", marginTop: "0.75rem" }}
                          >
                            <img
                              src={speaker?.photo}
                              alt={speaker?.id}
                              height={"40px"}
                              width={"40px"}
                              style={{ borderRadius: "50%" }}
                            />
                            <div>
                              <p
                                className="gray95014400"
                                style={{ fontWeight: 500 }}
                              >
                                {speaker?.name}
                              </p>
                              <p
                                className="gray80014400"
                                style={{ marginTop: "0.25rem" }}
                              >
                                {speaker?.department}
                              </p>
                            </div>
                          </div>
                        ) : (
                          <p className="headersmalltext">{speaker}</p>
                        )
                      )}
                    </div>

                    {event.addedSpeakers.length > 0 && (
                      <div style={{ marginTop: "1.5rem" }}>
                        <p className="gray95014400" style={{ fontWeight: 500 }}>
                          Speakers
                        </p>
                        <div
                          className="d-flex flex-wrap align-items-center"
                          style={{ gap: "1.5rem", marginTop: "0.75rem" }}
                        >
                          {event.addedSpeakers.map((speaker, index) =>
                            speaker?.name ? (
                              <div
                                key={index}
                                className="d-flex align-items-center"
                                style={{ gap: "0.75rem", marginTop: "0.75rem" }}
                              >
                                <img
                                  src={speaker?.photo}
                                  alt={speaker?.id}
                                  height={"40px"}
                                  width={"40px"}
                                  style={{ borderRadius: "50%" }}
                                />
                                <div>
                                  <p
                                    className="gray95014400"
                                    style={{ fontWeight: 500 }}
                                  >
                                    {speaker?.name}
                                  </p>
                                  <p
                                    className="gray80014400"
                                    style={{ marginTop: "0.25rem" }}
                                  >
                                    {speaker?.department}
                                  </p>
                                </div>
                              </div>
                            ) : (
                              <p className="headersmalltext">{speaker}</p>
                            )
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div
                  style={{
                    width: "300px",
                    minWidth: "300px",
                    maxWidth: "300px",
                  }}
                >
                  <div
                    className="shadowa "
                    style={{
                      background: "#FFF",
                      padding: "1rem",
                      width: "100%",
                    }}
                  >
                    <p className="gray95016500" style={{ fontWeight: 600 }}>
                      Similar Company
                    </p>
                    <div className="d-flex flex-column">
                      {similar &&
                        similar.map((sim, index) => (
                          <div
                            className="d-flex "
                            key={index}
                            style={{ marginTop: "1.5rem", gap: "0.75rem" }}
                          >
                            <div className="d-flex">
                              <CompanyImage
                                src={JSON.parse(sim.company).img}
                                height={"40px"}
                                width={"40px"}
                              />
                            </div>
                            <div className="d-flex flex-column gap-1">
                              <p
                                className="gray95014400"
                                style={{ fontWeight: 600 }}
                              >
                                {truncateString(sim?.title, 40)}
                              </p>
                              <p
                                className="gray50012500"
                                style={{ fontWeight: 400 }}
                              >
                                {truncateString(JSON.parse(sim?.company).title)}
                              </p>
                              <a
                                className="pink14 hovercolorback"
                                href={`/student/dashboard/event/${sim?.link}`}
                                style={{ textDecoration: "none" }}
                              >
                                View event
                              </a>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <p className="black14">No Event to show</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default MainEventDetails;
