const GroupCard = ({ group }) => {
  const truncateString = (str, maxLength) => {
    if (str.length > maxLength) {
      return str.substring(0, maxLength) + "...";
    } else {
      return str;
    }
  };

  return (
    <div className="group-card-container">
      <img
        src={group.profilePhoto}
        alt="profile"
        style={{ height: "40px", width: "40px", borderRadius: "50%" }}
        className="shadowa"
      />
      <div className="d-flex">
        <a
          href={`/student/dashboard/group/${group.link}`}
          target="_blank"
          style={{ textDecoration: "none" }}
        >
          <p
            className="gray95016500 hoverred"
            style={{ marginTop: "1rem", cursor: "pointer" }}
          >
            {group?.title}
          </p>
        </a>
      </div>
      {group?.about && (
        <p
          className="gray60011400"
          style={{ marginTop: "1rem", fontSize: "14px" }}
        >
          {truncateString(group?.about, 80)}
        </p>
      )}
      <div
        style={{
          width: "100%",
          margin: "1rem 0rem",
          height: "1px",
          background: "#E4E4E4",
        }}
      />
      <div
        className="space flex-wrap align-items-center"
        style={{ gap: "1rem" }}
      >
        <p
          className="gray50012500"
          style={{ fontSize: "14px", fontWeight: 400 }}
        >
          {group.members.length} Members
        </p>
        <button
          className="continuebutton "
          style={{
            background: "transparent",
            color: "#E31b54",
            border: "1px solid #E31b54",
            padding: "12px 20px",
          }}
        >
          Join
        </button>
      </div>
    </div>
  );
};
export default GroupCard;
