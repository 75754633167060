import AccessTimeIcon from "@mui/icons-material/AccessTime";
import GetTimeRange from "./getTimeRange";
import { HiMiniUsers } from "react-icons/hi2";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import axios from "axios";
import { toast } from "react-toastify";
import { useState } from "react";
import { useMediaQuery } from "@mui/material";
import CompanyImage from "../../components/Company/CompanyImage";
import EventTypes from "../NewPages/Events/EventsTypes";
const EventDetails = ({ event, sample, user, setEvents, fullview = false }) => {
  const [loading, setLoading] = useState(false);

  function formatDate(inputDate) {
    // Create a new Date object from the input string
    const date = new Date(inputDate);

    // Format options for day of the week, month, day, and year
    const options = {
      weekday: "short", // 'Wed'
      year: "numeric", // '2025'
      month: "short", // 'Jan'
      day: "2-digit", // '03'
    };

    // Format the date
    const formattedDate = date.toLocaleDateString("en-US", options);

    return formattedDate.replace(/,/g, ","); // Ensures correct format with commas
  }

  const handleAttendEvent = async () => {
    if (!loading) {
      setLoading(true);

      await axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/api/event/apply`,
          { eventId: event.uniqueId },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((res) => {
          if (res.data.msg === "added") {
            toast.success("Successfully added to participants");
            setEvents((prev) => {
              return prev.map((ev) => {
                if (ev.uniqueId === event.uniqueId) {
                  return {
                    ...ev,
                    participants: [...ev.participants, user._id], // Add the user ID
                  };
                }
                return ev;
              });
            });
          } else if (res.data.msg === "removed") {
            toast.success("Successfully removed to participants");
            setEvents((prev) => {
              return prev.map((ev) => {
                if (ev.uniqueId === event.uniqueId) {
                  return {
                    ...ev,
                    participants: ev.participants.filter(
                      (id) => id !== user._id
                    ), // Remove the user ID
                  };
                }
                return ev;
              });
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
      setLoading(false);
    }
  };

  return (
    <div>
      <img
        src={event?.banner ? event?.banner : sample}
        alt="sample"
        className="img-fluid"
        style={{ borderRadius: "10px 10px 0px 0px" }}
      />
      <div style={{ padding: fullview ? "1.5rem" : "" }}>
        {fullview && (
          <div
            className="d-flex flex-column"
            style={{ gap: "0.5rem", width: "100%" }}
          >
            <div
              className="d-flex"
              style={{ gap: "1rem", flexDirection: "row" }}
            >
              {event?.company && (
                <CompanyImage
                  src={JSON.parse(event.company).img}
                  height={"58px"}
                  width={"58px"}
                />
              )}
              <p
                style={{
                  fontWeight: 400,
                  width: "100%",
                  fontSize: "20px",
                  lineHeight: "24px",
                  color: "#0C111D",
                }}
              >
                {event?.title}
              </p>
            </div>

            <div className="d-flex flex-wrap" style={{ gap: "1rem" }}>
              <div className="d-flex">
                <EventTypes
                  noback={true}
                  type={
                    event?.type === "varyance Livestream"
                      ? "Live Stream"
                      : event?.type === "Job Schedule"
                      ? "Job Closings"
                      : event.type
                  }
                />
              </div>
              <p
                className="gray60011400 d-flex align-items-center gap-2"
                style={{
                  fontWeight: 500,
                  fontSize: "14px",
                  lineHeight: "20px",
                }}
              >
                Company:{" "}
                <span className="gray80014400">
                  {JSON.parse(event?.company)?.title}
                </span>
              </p>
            </div>
          </div>
        )}
        <div
          className="d-flex flex-wrap align-items-center"
          style={{ margin: "1.5rem 0rem", gap: "2rem" }}
        >
          <div className="d-flex align-items-center" style={{ gap: "0.75rem" }}>
            {fullview && (
              <p className="gray80014400" style={{ fontWeight: 500 }}>
                {formatDate(event.datetime)}
              </p>
            )}
            <div
              className="d-flex align-items-center"
              style={{ gap: "0.75rem" }}
            >
              <AccessTimeIcon style={{ height: "20px", width: "20px" }} />
              <p className="headersmalltext">
                <GetTimeRange
                  datetime={event?.datetime}
                  duration={event?.duration}
                />
              </p>
            </div>
            <div
              className="d-flex align-items-center"
              style={{ gap: "0.75rem" }}
            >
              <HiMiniUsers height={20} width={20} />
              <p className="headersmalltext">{event?.participants?.length}</p>
            </div>
          </div>
          <div className="d-flex align-items-center" style={{ gap: "0.75rem" }}>
            <p
              className="gray95014400 d-flex gap-2"
              style={{ fontWeight: 500 }}
            >
              Topic: <span className="gray80014400">{event?.topic}</span>
            </p>
          </div>
          <div className="newdashboardsidechips">{event?.language}</div>
        </div>
        <div
          className="d-flex flex-wrap align-items-center"
          style={{ gap: "1rem" }}
        >
          <button
            className="continuebutton"
            onClick={() => {
              handleAttendEvent();
            }}
            disabled={loading}
          >
            {event.participants.some((par) => par === user._id)
              ? "Miss"
              : "Attend"}
          </button>
          <button
            className="backbutton"
            onClick={() => {
              const link = `${process.env.REACT_APP_FRONTEND_URL}/student/dashboard/event/${event?.link}`;

              navigator.clipboard
                .writeText(link)
                .then(() => {
                  toast.success("Link copied to clipboard!");
                })
                .catch((err) => {
                  console.error("Failed to copy: ", err);
                  toast.error("Failed to copy link.");
                });
            }}
          >
            Share Event
          </button>
          <OpenInNewIcon
            className="hoverred"
            style={{ cursor: "pointer" }}
            onClick={() => {
              window.open(`/student/dashboard/event/${event?.link}`, "_blank");
            }}
          />
        </div>
        <div style={{ marginTop: "1.5rem" }}>
          <p className="gray95014400" style={{ fontWeight: 500 }}>
            About the event
          </p>
          <div
            className="allpoppins"
            style={{ marginTop: "0.75rem", fontWeight: 400, color: "#475467" }}
            dangerouslySetInnerHTML={{
              __html: event?.description,
            }}
          />
        </div>
        {!fullview && (
          <>
            <div style={{ marginTop: "0.75rem" }}>
              <p className="gray95014400" style={{ fontWeight: 500 }}>
                Categories
              </p>
              <div
                className="d-flex flex-wrap"
                style={{ gap: "0.75rem 1rem", marginTop: "0.75rem" }}
              >
                {event.selectedItem.length > 0 &&
                  event.selectedItem.map((item, index) => (
                    <div className="newdashboardsidechips" key={index}>
                      <img
                        src={item.icon}
                        alt={item.id}
                        height={"16px"}
                        width={"16px"}
                      />
                      {item.text}
                    </div>
                  ))}
              </div>
            </div>
            {event.addedSpeakers.length > 0 && (
              <div style={{ marginTop: "1.5rem" }}>
                <p className="gray95014400" style={{ fontWeight: 500 }}>
                  Speakers
                </p>
                <div
                  className="d-flex flex-wrap align-items-center"
                  style={{ gap: "1.5rem" }}
                >
                  {event.addedSpeakers.map((speaker, index) =>
                    speaker?.name ? (
                      <div
                        key={index}
                        className="d-flex align-items-center"
                        style={{ gap: "0.75rem", marginTop: "0.75rem" }}
                      >
                        <img
                          src={speaker?.photo}
                          alt={speaker?.id}
                          height={"40px"}
                          width={"40px"}
                          style={{ borderRadius: "50%" }}
                        />
                        <div>
                          <p
                            className="gray95014400"
                            style={{ fontWeight: 500 }}
                          >
                            {speaker?.name}
                          </p>
                          <p
                            className="gray80014400"
                            style={{ marginTop: "0.25rem" }}
                          >
                            {speaker?.department}
                          </p>
                        </div>
                      </div>
                    ) : (
                      <p className="headersmalltext">{speaker}</p>
                    )
                  )}
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default EventDetails;
